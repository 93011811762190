import React from 'react'
import { Link } from 'react-router-dom'
import blogData from "../blogs/DataBlog.json"


const BlogFixComponent = () => {
  
  return (
    <div className="our-blog-page-col-2-fix">
    <div className="our-blog-page-col-2">

    <div className="our-blog-page-col-2-col">
    <div className="our-blog-page-col-2-col-heading">Connect & Follow</div>
    <div className="our-blog-page-col-2-col-icon">
        <Link title='Garage On Call - Facebook' target='_blank' to="https://www.facebook.com/garageoncallofficial/" ><i class="fa-brands fa-facebook-f"></i></Link>
        <Link title='Garage On Call - X / Twitter' target='_blank' to="https://x.com/i/flow/login?redirect_after_login=%2FGarageoncall" ><i class="fa-brands fa-x-twitter"></i></Link>
        <Link title='Garage On Call - Youtube' target='_blank' to="https://www.youtube.com/@GarageOnCall" ><i class="fa-brands fa-youtube"></i></Link>
        <Link title='Garage On Call - LinkedIn' target='_blank' to="https://www.linkedin.com/company/garage-on-call/" ><i class="fa-brands fa-linkedin-in"></i></Link>         
        <Link title='Garage On Call - Instagram' target='_blank' to="https://www.instagram.com/garageoncallofficial/" ><i class="fa-brands fa-instagram"></i></Link>
      </div>

    </div>
      

    <div className="our-blog-page-col-2-col">
    <div className="our-blog-page-col-2-col-heading">Latest Blog</div>
    {/* ------------------------------------------- */}


{
  blogData.slice(0, 4).map(e=>(

    <div className="our-blog-page-col-2-content">
      <Link title={`${e.category} - blog`} to={e.link}>
    <div className="our-blog-page-col-2-content-heading">{e.title}</div>
      </Link>
    </div>
    
  ))
  }

    
    {/* ------------------------------------------- */}

    </div>
      
    </div>
    </div>
  )
}

export default BlogFixComponent