import React, { useState } from 'react'

const FaqComponent = () => {

    const [show, setShow] = useState(false)
    
  return (
    <div>
        <div className="faq-comp">
        <div className="faq-comp-head">
            <span></span>
        <div className="faq-comp-head-heading">Frequently Asked <span>Questions</span>
      {/* <div className="heading-underline"><hr /></div> */}
      </div>
        
        {
            show?
            <span onClick={e=>{setShow(false)}}>read less</span>
            :
            <span onClick={e=>{setShow(true)}}>read more</span>
        }
        </div>

        {
            show?
        
        <div className="faq-comp-heading-content">
            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What types of two-wheelers do you service?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call provides services for a wide range of two-wheelers, including bikes, super bikes, scooters, motorcycles, mopeds, and more.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What areas do you cover for doorstep service?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Knowing the service coverage area ensures that customers can avail of Garage On Call's services at their preferred location in Noida, Greater Noida, Ghaziabad, Delhi, Faridabad and Gurgaon.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What services do you offer at the doorstep?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call offers comprehensive services, including regular maintenance, repairs, oil changes, tire replacements, battery checks, engine repair, and more, conveniently performed at your doorstep.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Do you provide emergency roadside assistance?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call offers emergency assistance for breakdowns or issues, providing prompt support wherever you are.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; How long does a typical service or repair take?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Understanding the estimated duration of service helps customers plan their schedule accordingly.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What safety measures do your technicians follow during home visits?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call technicians follow strict safety protocols to ensure a secure service experience, including wearing protective gear and maintaining hygiene standards.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What are your service charges, and are there any hidden costs?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call provides transparent pricing with no hidden costs, ensuring customers know exactly what to expect.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Do you provide warranty or guarantee for the services rendered?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call stands behind the quality of our work, offering warranties or guarantees on services provided.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; How do I schedule an appointment for service at my preferred location?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Customers can easily schedule appointments through the Garage On Call website, whatsapp or by calling our customer service hotline.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What payment methods do you accept?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call accepts various payment methods for customer convenience, including cash, credit/debit cards, and mobile wallets.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Do I need to provide any tools or equipment for the service?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call technicians come fully equipped with all the necessary tools and equipment to perform the service at your doorstep.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Can I track the status of my service appointment?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Customers can track the status of our service appointment in real-time through the Garage On Call app or website.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What if I'm not satisfied with the service provided?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call prioritizes customer satisfaction and will address any concerns promptly to ensure a positive service experience.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Do you offer discounts or promotional offers for recurring customers?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call may have special discounts or offers for loyal customers, so it's worth inquiring about any available promotions.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Are your technicians certified and experienced?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call technicians are highly skilled, certified professionals with extensive experience in servicing two-wheelers.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What sets your doorstep service apart from traditional garage visits?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call's doorstep service offers unparalleled convenience, saving customers time and hassle by bringing expert service directly to your location.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Do you provide eco-friendly or green service options?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call is committed to environmental sustainability and may offer eco-friendly service options, such as eco-friendly cleaning products or recycling used parts.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; Can I schedule a service for multiple two-wheelers at once?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Customers can schedule service for multiple two-wheelers in a single appointment for added convenience.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; What should I do if I need to cancel or reschedule my appointment?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Customers can easily cancel or reschedule their appointments through the Garage On Call by contacting customer service.</div>

            
        <div className="faq-comp-heading-content-ques">Ques. &nbsp; How can I provide feedback about my service experience?</div>
        <div className="faq-comp-heading-content-ans"><span>Ans. </span>Garage On Call welcomes feedback from customers and provides various channels for sharing your experiences, including online reviews and feedback forms.</div>

        {
            show?
            <div className='faq-comp-heading-content-span'>
                <span onClick={e=>{setShow(false)}}>read less</span>
            </div>
            :
            ""
        }

        </div>
        :""
}

        </div>
    </div>
  )
}

export default FaqComponent