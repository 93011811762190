import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import AboutComponent from '../../components/user/AboutComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';

const AboutPage = () => {
  return (            
            <div>

    <Helmet>
            <title>Bike servicing and Repairing at home in Noida and Delhi</title>
            <meta name="description" content="
            We offer Bike servicing at home in Noida and Delhi. Our Online Mechanics reach at customer preferred location whether it’s home or office Call: 9658124124
            " />
          </Helmet>

      
      <Navbar />

<AboutComponent />

<Footer />

<ButtonComponent />

    </div>
  )
}

export default AboutPage