import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import LoadingComponent from '../user/LoadingComponent';

const AdminNav = () => {


  const [loading, setLoading] = useState(false);

  
  
  const navigate = useNavigate()
  const handleLogout = () => {
    setLoading(true);

    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("userEmail");
    alert("Admin logout successful");
    navigate("/admin");
    window.location.reload();
    setLoading(false);

  };
  
  if(!localStorage.getItem("userAuthToken")){
    return <Navigate to="/admin"/>
  }
  return (
    <div>
      
{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

<div className='admin-nav'>
<Link to="/admin/home" className='nav-logo admin-nav-logo'><div><span> <i className="fa-solid fa-screwdriver-wrench"></i> Garage</span> On Call</div></Link>

      
    <div className='admin-nav-btn' onClick={handleLogout}>
      logout
    </div>
</div>

    </div>
  )
}

export default AdminNav