import React, { useState } from 'react'
// import img1 from "../images/BIKE BANNER11-01.jpg"
// import img2 from "../images/BIKE BANNER22222-02.jpg"
import img1 from "../../images/online-bike-service-delhi.jpg"
import img2 from "../../images/activa-service-at-home.jpg"
import img3 from "../../images/bike-doorstep-service.jpg"
import img4 from "../../images/online-scooty-service.jpg"

import Slider from "react-slick";
import PopupNumberForm from '../forms/PopupNumberForm'


const OfferSlider = () => {

    const [form, setForm] = useState(false)
    const [heading, setHeading] = useState()
    const [heading2, setHeading2] = useState()


    
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
  return (
    <div >
{
    form?
    <PopupNumberForm open={setForm} heading={heading} text="(Limted  Time Offer*)" heading2={heading2}/>
    :""
}
        
    <div className='offer-main'>

    <div className='offer-main-heading-1'>Popular <span>Offers</span></div>
    <div className='offer-main-heading-2'>Limited Period Only*</div>

        <div className='offer-slider'>

        <Slider {...settings}>
                {/* ---slide---- */}
               
                    <div className='offer-img' >
                       <img onClick={e=>(setForm(true) || setHeading("Upto 10% Off on Wiring") || setHeading2("Now Get Your Bike Wiring Service at Home and Save Your Time and Money!"))} src={img1} alt='offer_image' title='Wiring Offer'/>
                    </div>
                
                {/* ---slide---- */}
               
                    <div className='offer-img'>
                       <img  onClick={e=>(setForm(true) || setHeading("Upto 10% Off on Spare Parts") || setHeading2("Now Get Your Bike Service at Home and Save Your Time and Money!"))} src={img2} alt='offer_image' title='Parts Discount'/>
                    </div>
                {/* ---slide---- */}
               
                    <div className='offer-img'>
                       <img onClick={e=>(setForm(true) || setHeading("Upto 20% Off on Service Charge") || setHeading2("Now Get Your Bike Service at Home and Save Your Time and Money!"))} src={img4} alt='offer_image' title='Service Charges discount'/>
                    </div>
                {/* ---slide---- */}
               
                    <div className='offer-img'>
                       <img onClick={e=>(setForm(true) || setHeading("Upto 10% off on Battery") || setHeading2("Now Get Your Bike Battery Service at Home and Save Your Time and Money!"))} src={img3} alt='offer_image' title='Battery discount'/>
                    </div>
              





            </Slider>
          
        </div>
        
    </div>
    </div>
  )
}

export default OfferSlider