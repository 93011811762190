import React from 'react'
import img1 from "../../images/bike-service.jpg"
import img2 from "../../images/bike-service-noida.jpg"
import TrustedBrandsComponents from './TrustedBrandsComponents'

const OurWork = () => {
    return (
        <div>


        <div className='bg-black'>
            <div className='our-work ' >
                <div className='our-work-img'><img src={img1} title='Best online bike service at your home' alt='bike-service' /></div>
                <div className='about-cu-t'>
                    <h2 className='our-work-txt-heading'>Why Choose <span>Garage On Call</span>?</h2>
                    
                    <p className='our-work-txt-text'>
                    At Garage On Call, we understand the significance of your two-wheeler, and we're dedicated to providing a service experience that goes beyond the ordinary. Here are compelling reasons why Garage On Call stands out as your preferred choice for doorstep bike and scooter service
                    </p>
                    <div className='our-work-txt-points'>
                        <div className=''>Convenience at Your Doorstep</div>
                        <div className=''>Expert Technicians</div>
                        <div className=''>Transparent Pricing</div>
                        <div className=''>Genuine Parts and Lubricants</div>
                        <div className=''>Customer Satisfaction Guaranteed</div>
                        <div className=''>Prompt and Professional Service</div>
                    </div>
                </div>

            </div>
            </div>

            <TrustedBrandsComponents />


            <div className='bg-black'>
            <div className='our-work'>
                <div className='about-cu-t'>
                    <h2 className='our-work-txt-heading'>How <span>Garage On Call</span> Works?</h2>
                    <p className='our-work-txt-text'>
                    Welcome to Garage On Call, where we redefine the two-wheeler service experience by bringing our expertise to your doorstep. Our streamlined process ensures a hassle-free and efficient service, saving you time and effort. Here's how Garage On Call works
                    </p>
                    <div className='our-work-txt-points'>
                        <div className=''>Book a Service</div>
                        <div className=''>Technician Dispatch</div>
                        <div className=''>Service On The Spot</div>
                        <div className=''>Transparent Communication</div>
                        <div className=''>Quality Assurance</div>
                        <div className=''>Payment and Feedback</div>
                    </div>
                </div>
                <div className='our-work-img'><img src={img2} title='Bike service at home' alt='bike-service-noida' /></div>

            </div>
            </div>


        </div>
    )
}

export default OurWork