import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';

const BookingForm = ({setForm}) => {

  
  
  const [brandOptions, setBrandOptions] = useState(false);
  const [modelOptions, setModelOptions] = useState(false);


  const [loading, setLoading] = useState(false);


  // ----------------------------

// const [form, setForm] = useState(false)



  // fetch ---------------------------------------------------------------------------

  const [brand, setBrand] = useState([]);
  const [brandName, setBrandName] = useState("--- select Brand ---");

  const [model, setModel] = useState([]);

  const loadData = async () => {
      let res = await fetch(`https://api.garageoncall.com/bikeApi`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo",
          },
      });
      res = await res.json();
      console.log("res", res.data);
      setBrand(res.data);
  };

  useEffect(() => {
      loadData();
  }, []);

  // console.log("model = ", model)



  
  
  // ------------------------- location -----------------------
  const [saveLocation, setSaveLocation] = useState()

  const getLocation = async () => {
    let location = await fetch(`https://ipapi.co/json`, {
      method: "GET",
    });
    const res = await location.json();
    
    setSaveLocation(res.city+ ", " + res.region)
    // localStorage.setItem("detect-City",  res.city+ ", " + res.region)
  };

  
  useEffect(() => {
    getLocation();
  }, []);
  
   // post ----------------------------------------------------------

   const navigate = useNavigate()

   
   const [user, setUser] = useState({
    bike: "",
    bikeCC: "",
    phone: "",
    address:"",
    // selectedCity : (!localStorage.getItem("selectedCity")?"not selected " : localStorage.getItem("selectedCity")),
    // detectedCity : localStorage.getItem("detect-City"),
    detectedCity : saveLocation,
    path: `location page - form`,
    message:"",
    user: "true",
    status: "true",
  });

   const handleChange = (e) => {
       const value = e.target.value;
       setUser({
           ...user,
           [e.target.name]: value,
       });
   };
 
   const handleSubmit = async (e) => {
       setLoading(true);
 
       e.preventDefault();
       try {
           const response = await fetch("https://api.shop.garageoncall.com/booking-form", {
               method: "POST",
               headers: {
                   "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"

               },
               body: JSON.stringify(user),
           });
 
           if (response.ok) {
               // setBookingForm(false);
               alert("Service booked successfully");
               navigate("/thanks");
 
           } else {
               // alert("invalid credential...");
               const res_data = await response.json();
               alert(res_data.message);
               // console.log("className=",res_data.message)
           }
 
           // console.log(response);
       } catch (error) {
           // console.log("catch err", error);
           alert("server error");
       }
       setLoading(false);
 
   };
   
  
  
  
  
  
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

<div className='price-page-form'>
        <div className='price-page-form-close'><span onClick={()=>{setForm(false)}}><i class="fa-solid fa-circle-xmark"></i></span></div>
            
            <div className=''>

        <div className='price-page-form-heading-1'>Welcome to <span> <i class="fa-solid fa-screwdriver-wrench"></i></span> Garage On Call</div>
        <div className='price-page-form-heading-2'>Book Best Doorstep Bike Service by Experts</div>

            <form className='sb-col-3 select-bike-form' onSubmit={handleSubmit}  >
                <div className='sb-c3-1'>
                    <div className='sb-c3-1-col'>
                        <div className="select-brand" onClick={e => { setBrandOptions(!brandOptions) }}><span>{brandName}</span><span><i class="fa-solid fa-angle-down"></i></span></div>
                        {
                            brandOptions === true ?
                                <div className='select-brand-options'>
                                    {
                                        brand.map(e => {
                                            return (
                                                <div onClick={() => { setModel(e.model); setBrandName(e.brand); setBrandOptions(false); setModelOptions(true) }}>{e.brand}</div>
                                            )
                                        })
                                    }
                                </div>
                                : ""
                        }
                    </div>
                    {
                        modelOptions === true ?
                            <>
                                <select required className="select-bike-inp" name='bike' onChange={handleChange} >
                                    <option disabled selected value="">--- Select Model ---</option>
                                    {
                                        model.map(e => {
                                            return <option>{e}</option>
                                        })
                                    }
                                </select>
                            </>
                            : ""
                    }
                </div>
                <select required className='select-bike-inp' name="bikeCC" id="">
                    <option disabled selected value="">--- select bike CC ---</option>
                {
                    modelOptions === true ?
                    <>
                      <option value="Below 125 CC - Rs. 229/-">Below 125 CC &nbsp; - &nbsp; Rs. 299/-</option>
                      <option value="125 CC to 199 CC - Rs. 399/-">125 CC to 199 CC &nbsp; - &nbsp; Rs. 399/-</option>
                      <option value="200 CC to 299 CC - Rs. 499/-">200 CC to 299 CC &nbsp; - &nbsp; Rs. 499/-</option>
                      <option value="300 CC to 350 CC - Rs. 599/-">300 CC to 350 CC &nbsp; - &nbsp; Rs. 599/-</option>
                      <option value="Above 350 CC - Rs. 999/-">Above 350 CC &nbsp; - &nbsp; Rs. 999/-</option>
                      <option value="don't remember">Don't remember</option>
                        </>

: 
<option disabled  value="">--- select model first ---</option>
}
                    </select>
                    
                <input required className='select-bike-inp text-center' type='number' placeholder='* Enter your phone number' name='phone' onChange={handleChange} />
                <input required className='select-bike-inp text-center' type='text' placeholder='*Enter your address or city' name='address' onChange={handleChange} />
                <input className='select-bike-inp text-center' type='text' placeholder='Special request...' name='message' onChange={handleChange} />
                <button className='select-bike-btn '><div className='pop-outin'>BOOK SERVICE</div> </button>
            </form>

        </div>
        </div>
      
    </div>
  )
}

export default BookingForm