import React, { useState } from "react";
import PopupNumberForm from "../../forms/PopupNumberForm";
import FormTermsAndConditionComponent from "../../forms/FormTermsAndConditionComponent";

const PriceChecklistComponent = ({ type, open, service }) => {
  const [numForm, setNumForm] = useState(false);
  const [heading, setHeading] = useState();

  const [err, setErr] = useState(false);

  
  const [price, setPrice] = useState();
  
  const [termsConditions, setTermsConditions] = useState(false);
  // ---------------------------------------------------------------

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
  };

  return (
    <div>
      {numForm ? <PopupNumberForm open={setNumForm} heading={heading} /> : ""}

      {termsConditions ? (
        <FormTermsAndConditionComponent open={setTermsConditions} price={price}/>
      ) : (
        ""
      )}


      {
        type === "At-Home"?

      
      <div>
        {service === "regular" ? (
          <div className="package-price-popup">
            <div className="package-price-popup-main">
              <div className="package-price-popup-head">
                <div className="package-price-popup-head-col">
                  <div className="package-price-popup-head-heading-1">
                    {type} Regular Service
                  </div>
                  <div className="package-price-popup-head-heading-2">
                    Below 125 CC
                  </div>
                </div>
                <div className="package-price-popup-head-col">
                  <i
                    onClick={(e) => open(false)}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                </div>
              </div>

              <div className="package-price-popup-content">
                <div className="package-price-popup-content-points">
                  <ul>
                    <li>Engine Oil Change (Price Extra)</li>
                    <li>Oil Filter Clean (if Replace Charges)</li>
                    <li>Air Filter Clean (if Replace Charges)</li>
                    <li>Spark Plug Clean (if Replace Charges)</li>
                    <li>Tightening of Screws, Bolts & Nuts</li>
                    <li>Average and Performance Check-up</li>
                    <li>Tyre Air Fill (only tubeless)</li>
                    <li>Brakes – Front & Rear Adjust</li>
                    <li>Driven Chain Basic Cleaning</li>
                    <li>Carburettor Basic Check-up</li>
                    <li>Minor Electrical Check-up</li>
                    <li>Battery General Check-up</li>
                    <li>Basic Engine Inspection</li>
                    <li>Basic Fork Inspection</li>
                    <li>Basic Hand Cleaning</li>
                    <li>Free Pick and Drop</li>
                  </ul>
                </div>

                <div className="package-price-popup-content-tc">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes, I agree to the{" "}
                  <span
                    onClick={(e) => setTermsConditions(true) || setPrice(299)}
                  >
                    Terms of Service
                  </span>
                </div>

                {err && !isChecked ? (
                  <div className="package-price-popup-content-err">
                    You must accept the terms of condition
                  </div>
                ) : (
                  ""
                )}

                <div
                  onClick={(e) =>
                    (isChecked && setNumForm(true)) ||
                    (!isChecked && setErr(true)) ||
                    setHeading(`${type} Regular Service`)
                  }
                  className={
                    isChecked
                      ? "package-price-popup-content-button"
                      : "package-price-popup-content-button-disabled"
                  }
                >
                  Book Now
                </div>
              </div>
            </div>
          </div>
        ) : service === "classic" ? (
          <div className="package-price-popup">
            <div className="package-price-popup-main">
              <div className="package-price-popup-head">
                <div className="package-price-popup-head-col">
                  <div className="package-price-popup-head-heading-1">
                    {type} Classic Service
                  </div>
                  <div className="package-price-popup-head-heading-2">
                    125 CC to 199 CC
                  </div>
                </div>
                <div className="package-price-popup-head-col">
                  <i
                    onClick={(e) => open(false)}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                </div>
              </div>

              <div className="package-price-popup-content">
                <div className="package-price-popup-content-points">
                  <ul>
                    <li>Engine Oil Change (Price Extra)</li>
                    <li>Oil Filter Clean (if Replace Charges)</li>
                    <li>Air Filter Clean (if Replace Charges)</li>
                    <li>Spark Plug Clean (if Replace Charges)</li>
                    <li>Tightening of Screws, Bolts & Nuts</li>
                    <li>Average and Performance Check-up</li>
                    <li>Tyre Air Fill (only tubeless)</li>
                    <li>Brakes – Front & Rear Adjust</li>
                    <li>Driven Chain Basic Cleaning</li>
                    <li>Carburettor Basic Check-up</li>
                    <li>Minor Electrical Check-up</li>
                    <li>Battery General Check-up</li>
                    <li>Basic Engine Inspection</li>
                    <li>Basic Fork Inspection</li>
                    <li>Basic Hand Cleaning</li>
                    <li>Oiling and Greasing</li>
                    <li>Coolant Check-up</li>
                    <li>Free Pick and Drop</li>
                  </ul>
                </div>

                <div className="package-price-popup-content-tc">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes, I agree to the{" "}
                  <span
                    onClick={(e) => setTermsConditions(true) || setPrice(399)}
                  >
                    Terms of Service
                  </span>
                </div>

                {err && !isChecked ? (
                  <div className="package-price-popup-content-err">
                    You must accept the terms of condition
                  </div>
                ) : (
                  ""
                )}

                <div
                  onClick={(e) =>
                    (isChecked && setNumForm(true)) ||
                    (!isChecked && setErr(true)) ||
                    setHeading(`${type} Classic Service`)
                  }
                  className={
                    isChecked
                      ? "package-price-popup-content-button"
                      : "package-price-popup-content-button-disabled"
                  }
                >
                  Book Now
                </div>
              </div>
            </div>
          </div>
        ) : service === "premium" ? (
          <div className="package-price-popup">
            <div className="package-price-popup-main">
              <div className="package-price-popup-head">
                <div className="package-price-popup-head-col">
                  <div className="package-price-popup-head-heading-1">
                    {type} Premium Service
                  </div>
                  <div className="package-price-popup-head-heading-2">
                    200 CC to 299 CC
                  </div>
                </div>
                <div className="package-price-popup-head-col">
                  <i
                    onClick={(e) => open(false)}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                </div>
              </div>

              <div className="package-price-popup-content">
                <div className="package-price-popup-content-points">
                  <ul>
                    <li>Engine Oil Change (Price Extra)</li>
                    <li>Oil Filter Clean (if Replace Charges)</li>
                    <li>Air Filter Clean (if Replace Charges)</li>
                    <li>Spark Plug Clean (if Replace Charges)</li>
                    <li>Tightening of Screws, Bolts & Nuts</li>
                    <li>Average and Performance Check-up</li>
                    <li>Tyre Air Fill (only tubeless)</li>
                    <li>Brakes – Front & Rear Adjust</li>
                    <li>Driven Chain Basic Cleaning</li>
                    <li>Carburettor Basic Check-up</li>
                    <li>Minor Electrical Check-up</li>
                    <li>Battery General Check-up</li>
                    <li>Basic Engine Inspection</li>
                    <li>Fuel Pump Motor Check-up</li>
                    <li>Basic Fork Inspection</li>
                    <li>Basic Hand Cleaning</li>
                    <li>Oiling and Greasing</li>
                    <li>Injector Check-up</li>
                    <li>Coolant Check-up</li>
                    <li>Free Pick and Drop</li>
                  </ul>
                </div>

                <div className="package-price-popup-content-tc">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes, I agree to the{" "}
                  <span
                    onClick={(e) => setTermsConditions(true) || setPrice(499)}
                  >
                    Terms of Service
                  </span>
                </div>

                {err && !isChecked ? (
                  <div className="package-price-popup-content-err">
                    You must accept the terms of condition
                  </div>
                ) : (
                  ""
                )}

                <div
                  onClick={(e) =>
                    (isChecked && setNumForm(true)) ||
                    (!isChecked && setErr(true)) ||
                    setHeading(`${type} Premium  Service`)
                  }
                  className={
                    isChecked
                      ? "package-price-popup-content-button"
                      : "package-price-popup-content-button-disabled"
                  }
                >
                  Book Now
                </div>
              </div>
            </div>
          </div>
        ) : service === "royal" ? (
          <div className="package-price-popup">
            <div className="package-price-popup-main">
              <div className="package-price-popup-head">
                <div className="package-price-popup-head-col">
                  <div className="package-price-popup-head-heading-1">
                    {type} Royal Service
                  </div>
                  <div className="package-price-popup-head-heading-2">
                    300 CC to 349 CC
                  </div>
                </div>
                <div className="package-price-popup-head-col">
                  <i
                    onClick={(e) => open(false)}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                </div>
              </div>

              <div className="package-price-popup-content">
                <div className="package-price-popup-content-points">
                  <ul>
                    <li>Engine Oil Change (Price Extra)</li>
                    <li>Oil Filter Clean (if Replace Charges)</li>
                    <li>Air Filter Clean (if Replace Charges)</li>
                    <li>Spark Plug Clean (if Replace Charges)</li>
                    <li>Tightening of Screws, Bolts & Nuts</li>
                    <li>Average and Performance Check-up</li>
                    <li>Tyre Air Fill (only tubeless)</li>
                    <li>Brakes – Front & Rear Adjust</li>
                    <li>Driven Chain Basic Cleaning</li>
                    <li>Carburettor Basic Check-up</li>
                    <li>Minor Electrical Check-up</li>
                    <li>Battery General Check-up</li>
                    <li>Basic Engine Inspection</li>
                    <li>Fuel Pump Motor Check-up</li>
                    <li>Basic Fork Inspection</li>
                    <li>Basic Hand Cleaning</li>
                    <li>Oiling and Greasing</li>
                    <li>Injector Check-up</li>
                    <li>Coolant Check-up</li>
                    <li>Free Pick and Drop</li>
                  </ul>
                </div>

                <div className="package-price-popup-content-tc">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes, I agree to the{" "}
                  <span
                    onClick={(e) => setTermsConditions(true) || setPrice(599)}
                  >
                    Terms of Service
                  </span>
                </div>

                {err && !isChecked ? (
                  <div className="package-price-popup-content-err">
                    You must accept the terms of condition
                  </div>
                ) : (
                  ""
                )}

                <div
                  onClick={(e) =>
                    (isChecked && setNumForm(true)) ||
                    (!isChecked && setErr(true)) ||
                    setHeading(`${type} Royal Service`)
                  }
                  className={
                    isChecked
                      ? "package-price-popup-content-button"
                      : "package-price-popup-content-button-disabled"
                  }
                >
                  Book Now
                </div>
              </div>
            </div>
          </div>
        ) : service === "sports" ? (
          <div className="package-price-popup">
            <div className="package-price-popup-main">
              <div className="package-price-popup-head">
                <div className="package-price-popup-head-col">
                  <div className="package-price-popup-head-heading-1">
                    {type} Sports Service
                  </div>
                  <div className="package-price-popup-head-heading-2">
                    Above 350 CC
                  </div>
                </div>
                <div className="package-price-popup-head-col">
                  <i
                    onClick={(e) => open(false)}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                </div>
              </div>

              <div className="package-price-popup-content">
                <div className="package-price-popup-content-points">
                  <ul>
                    <li>Engine Oil Change (Price Extra)</li>
                    <li>Oil Filter Clean (if Replace Charges)</li>
                    <li>Air Filter Clean (if Replace Charges)</li>
                    <li>Spark Plug Clean (if Replace Charges)</li>
                    <li>Tightening of Screws, Bolts & Nuts</li>
                    <li>Average and Performance Check-up</li>
                    <li>Tyre Air Fill (only tubeless)</li>
                    <li>Brakes – Front & Rear Adjust</li>
                    <li>Driven Chain Basic Cleaning</li>
                    <li>Carburettor Basic Check-up</li>
                    <li>Minor Electrical Check-up</li>
                    <li>Battery General Check-up</li>
                    <li>Basic Engine Inspection</li>
                    <li>Fuel Pump Motor Check-up</li>
                    <li>Basic Fork Inspection</li>
                    <li>Basic Hand Cleaning</li>
                    <li>Oiling and Greasing</li>
                    <li>Injector Check-up</li>
                    <li>Coolant Check-up</li>
                    <li>Free Pick and Drop</li>

                    {/* <li>Fuel Pump Motor Check-up</li>
<li>Oiling and Greasing</li>
<li>Injector Check-up</li>
<li>Coolant Check-up</li> */}
                  </ul>
                </div>

                <div className="package-price-popup-content-tc">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes, I agree to the{" "}
                  <span
                    onClick={(e) => setTermsConditions(true) || setPrice(999)}
                  >
                    Terms of Service
                  </span>
                </div>

                {err && !isChecked ? (
                  <div className="package-price-popup-content-err">
                    You must accept the terms of condition
                  </div>
                ) : (
                  ""
                )}

                <div
                  onClick={(e) =>
                    (isChecked && setNumForm(true)) ||
                    (!isChecked && setErr(true)) ||
                    setHeading(`${type} Sports Service`)
                  }
                  className={
                    isChecked
                      ? "package-price-popup-content-button"
                      : "package-price-popup-content-button-disabled"
                  }
                >
                  Book Now
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

: type === "Road Side"?


<div>
  
{service === "regular" ? (
  <div className="package-price-popup">
    <div className="package-price-popup-main">
      <div className="package-price-popup-head">
        <div className="package-price-popup-head-col">
          <div className="package-price-popup-head-heading-1">
            {type} Regular Service
          </div>
          <div className="package-price-popup-head-heading-2">
            Below 125 CC
          </div>
        </div>
        <div className="package-price-popup-head-col">
          <i
            onClick={(e) => open(false)}
            className="fa-solid fa-circle-xmark"
          ></i>
        </div>
      </div>

      <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
         
         <ul>
            <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
            <li>Carburettor Basic Check-up</li>
            <li>Minor Electrical Check-up</li>
            <li>Battery General Check-up</li>
            <li>Basic Engine Inspection</li>
            <li>Fuel Pump Motor Check-up</li>
            <li>Tyre Air Fill (only tubeless)</li>
            <li>Injector Check-up</li>
            <li>Coolant Check-up</li>
            <li>Free Pick and Drop</li>
         </ul>
         
        </div>

        <div className="package-price-popup-content-tc">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{" "}
          Yes, I agree to the{" "}
          <span
            onClick={(e) => setTermsConditions(true) || setPrice(299)}
          >
            Terms of Service
          </span>
        </div>

        {err && !isChecked ? (
          <div className="package-price-popup-content-err">
            You must accept the terms of condition
          </div>
        ) : (
          ""
        )}

        <div
          onClick={(e) =>
            (isChecked && setNumForm(true)) ||
            (!isChecked && setErr(true)) ||
            setHeading(`${type} Regular Service`)
          }
          className={
            isChecked
              ? "package-price-popup-content-button"
              : "package-price-popup-content-button-disabled"
          }
        >
          Book Now
        </div>
      </div>
    </div>
  </div>
) : service === "classic" ? (
  <div className="package-price-popup">
    <div className="package-price-popup-main">
      <div className="package-price-popup-head">
        <div className="package-price-popup-head-col">
          <div className="package-price-popup-head-heading-1">
            {type} Classic Service
          </div>
          <div className="package-price-popup-head-heading-2">
            125 CC to 199 CC
          </div>
        </div>
        <div className="package-price-popup-head-col">
          <i
            onClick={(e) => open(false)}
            className="fa-solid fa-circle-xmark"
          ></i>
        </div>
      </div>

      <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
        
        <ul>
            <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
            <li>Carburettor Basic Check-up</li>
            <li>Minor Electrical Check-up</li>
            <li>Battery General Check-up</li>
            <li>Basic Engine Inspection</li>
            <li>Fuel Pump Motor Check-up</li>
            <li>Tyre Air Fill (only tubeless)</li>
            <li>Injector Check-up</li>
            <li>Coolant Check-up</li>
            <li>Free Pick and Drop</li>
         </ul>
        
        </div>

        <div className="package-price-popup-content-tc">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{" "}
          Yes, I agree to the{" "}
          <span
            onClick={(e) => setTermsConditions(true) || setPrice(399)}
          >
            Terms of Service
          </span>
        </div>

        {err && !isChecked ? (
          <div className="package-price-popup-content-err">
            You must accept the terms of condition
          </div>
        ) : (
          ""
        )}

        <div
          onClick={(e) =>
            (isChecked && setNumForm(true)) ||
            (!isChecked && setErr(true)) ||
            setHeading(`${type} Classic Service`)
          }
          className={
            isChecked
              ? "package-price-popup-content-button"
              : "package-price-popup-content-button-disabled"
          }
        >
          Book Now
        </div>
      </div>
    </div>
  </div>
) : service === "premium" ? (
  <div className="package-price-popup">
    <div className="package-price-popup-main">
      <div className="package-price-popup-head">
        <div className="package-price-popup-head-col">
          <div className="package-price-popup-head-heading-1">
            {type} Premium Service
          </div>
          <div className="package-price-popup-head-heading-2">
            200 CC to 299 CC
          </div>
        </div>
        <div className="package-price-popup-head-col">
          <i
            onClick={(e) => open(false)}
            className="fa-solid fa-circle-xmark"
          ></i>
        </div>
      </div>

      <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
        <ul>
            <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
            <li>Carburettor Basic Check-up</li>
            <li>Minor Electrical Check-up</li>
            <li>Battery General Check-up</li>
            <li>Basic Engine Inspection</li>
            <li>Fuel Pump Motor Check-up</li>
            <li>Tyre Air Fill (only tubeless)</li>
            <li>Injector Check-up</li>
            <li>Coolant Check-up</li>
            <li>Free Pick and Drop</li>
         </ul>
        </div>

        <div className="package-price-popup-content-tc">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{" "}
          Yes, I agree to the{" "}
          <span
            onClick={(e) => setTermsConditions(true) || setPrice(499)}
          >
            Terms of Service
          </span>
        </div>

        {err && !isChecked ? (
          <div className="package-price-popup-content-err">
            You must accept the terms of condition
          </div>
        ) : (
          ""
        )}

        <div
          onClick={(e) =>
            (isChecked && setNumForm(true)) ||
            (!isChecked && setErr(true)) ||
            setHeading(`${type} Premium  Service`)
          }
          className={
            isChecked
              ? "package-price-popup-content-button"
              : "package-price-popup-content-button-disabled"
          }
        >
          Book Now
        </div>
      </div>
    </div>
  </div>
) : service === "royal" ? (
  <div className="package-price-popup">
    <div className="package-price-popup-main">
      <div className="package-price-popup-head">
        <div className="package-price-popup-head-col">
          <div className="package-price-popup-head-heading-1">
            {type} Royal Service
          </div>
          <div className="package-price-popup-head-heading-2">
            300 CC to 349 CC
          </div>
        </div>
        <div className="package-price-popup-head-col">
          <i
            onClick={(e) => open(false)}
            className="fa-solid fa-circle-xmark"
          ></i>
        </div>
      </div>

      <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
        <ul>
            <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
            <li>Carburettor Basic Check-up</li>
            <li>Minor Electrical Check-up</li>
            <li>Battery General Check-up</li>
            <li>Basic Engine Inspection</li>
            <li>Fuel Pump Motor Check-up</li>
            <li>Tyre Air Fill (only tubeless)</li>
            <li>Injector Check-up</li>
            <li>Coolant Check-up</li>
            <li>Free Pick and Drop</li>
         </ul>
        </div>

        <div className="package-price-popup-content-tc">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{" "}
          Yes, I agree to the{" "}
          <span
            onClick={(e) => setTermsConditions(true) || setPrice(599)}
          >
            Terms of Service
          </span>
        </div>

        {err && !isChecked ? (
          <div className="package-price-popup-content-err">
            You must accept the terms of condition
          </div>
        ) : (
          ""
        )}

        <div
          onClick={(e) =>
            (isChecked && setNumForm(true)) ||
            (!isChecked && setErr(true)) ||
            setHeading(`${type} Royal Service`)
          }
          className={
            isChecked
              ? "package-price-popup-content-button"
              : "package-price-popup-content-button-disabled"
          }
        >
          Book Now
        </div>
      </div>
    </div>
  </div>
) : service === "sports" ? (
  <div className="package-price-popup">
    <div className="package-price-popup-main">
      <div className="package-price-popup-head">
        <div className="package-price-popup-head-col">
          <div className="package-price-popup-head-heading-1">
            {type} Sports Service
          </div>
          <div className="package-price-popup-head-heading-2">
            Above 350 CC
          </div>
        </div>
        <div className="package-price-popup-head-col">
          <i
            onClick={(e) => open(false)}
            className="fa-solid fa-circle-xmark"
          ></i>
        </div>
      </div>

      <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
        <ul>
            <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
            <li>Carburettor Basic Check-up</li>
            <li>Minor Electrical Check-up</li>
            <li>Battery General Check-up</li>
            <li>Basic Engine Inspection</li>
            <li>Fuel Pump Motor Check-up</li>
            <li>Tyre Air Fill (only tubeless)</li>
            <li>Injector Check-up</li>
            <li>Coolant Check-up</li>
            <li>Free Pick and Drop</li>
         </ul>
        </div>

        <div className="package-price-popup-content-tc">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{" "}
          Yes, I agree to the{" "}
          <span
            onClick={(e) => setTermsConditions(true) || setPrice(999)}
          >
            Terms of Service
          </span>
        </div>

        {err && !isChecked ? (
          <div className="package-price-popup-content-err">
            You must accept the terms of condition
          </div>
        ) : (
          ""
        )}

        <div
          onClick={(e) =>
            (isChecked && setNumForm(true)) ||
            (!isChecked && setErr(true)) ||
            setHeading(`${type} Sports Service`)
          }
          className={
            isChecked
              ? "package-price-popup-content-button"
              : "package-price-popup-content-button-disabled"
          }
        >
          Book Now
        </div>
      </div>
    </div>
  </div>
) : (
  ""
)}
</div>



      :""}

    </div>
  );
};

export default PriceChecklistComponent;
