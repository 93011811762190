import React from "react";
import Navbar from "../../components/user/Navbar";
import Footer from "../../components/user/Footer";
import { Header } from "../../components/user/Header";
import OfferSlider from "../../components/user/OfferSlider";
// import OurService from "../../components/user/OurService";
import BookingComponent from "../../components/user/BookingComponent";
import OurWork from "../../components/user/OurWork";
import ButtonComponent from "../../components/user/ButtonComponent";
// import KeyWords from '../../components/user/KeyWords'
import { Helmet } from "react-helmet";
import LocationComponent from "../../components/user/LocationComponent";
import FaqComponent from "../../components/user/FaqComponent";
import ClientSaysComponent from "../../components/user/ClientSaysComponent";
import CategoryComponent from "../../components/user/CategoryComponent";
import LatestPostComponent from "../../components/user/LatestPostComponent";
import OurBenefitsComponent from "../../components/user/OurBenefitsComponent";
// import TrustedBrandsComponents from "../../components/user/TrustedBrandsComponents";
// import HeaderTopComponent from "../../components/user/HeaderTopComponent";
import NewTestimonialComponent from "../../components/user/NewTestimonialComponent";
import BikeBrandLocationComponent from "../../components/user/BikeBrandLocationComponent";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>
          Garage on call offers Bike servicing at home in Noida and Delhi
        </title>
        <meta
          name="description"
          content="
        Garage on call - We offers Bike servicing at home in Noida, Delhi, Gurgaon and Ghaziabad. Call us for doorstep bike servicing and repairing +91 9658124124
        "
        />
      </Helmet>

      <Navbar />
      <Header />
      <OfferSlider />


<div className="bg-black">
      <ClientSaysComponent />
</div>

      <BookingComponent heading="Home Page Booking Form" />

<div className="bg-black">
      <CategoryComponent />
</div>

      <OurBenefitsComponent />

      <OurWork />
      <LocationComponent />
<div className="bg-black">
<NewTestimonialComponent/>
</div>
<BikeBrandLocationComponent/>

<div className="bg-black">
      <LatestPostComponent />
</div>

      <FaqComponent />


<div className="bg-black">

</div>

      



      

      {/* <KeyWords/> */}

      <Footer />

      <ButtonComponent popupStatus={false} />
    </div>
  );
};

export default Home;
