import React from 'react'
import img from '../../../images/blogs/bike-servicing.jpg'
import Footer from '../../user/Footer'
import TopBlog from '../TopBlog'
import BlogSeo from './BlogSeo'
import BlogNavbar from '../BlogNavbar'


const BlogCorona = () => {
  return (
    <div>

<BlogSeo/>
      
<div>
    <BlogNavbar />

    <div className='single-blog-main'>

      <div className='single-blog-cols'>

        <div className='s-b-col'> 

          <div className='s-b-col-1'>
            <div className='s-b-c-head'>
              <h1 className='s-b-c-h-h-1'>Corona LOCK DOWN.. Here are the tips by Garage on Call to Keep your Bike Healthy</h1>
              <div className='s-b-c-h-h-2'>
                <div><i class="fa-solid fa-user"></i> ADMIN</div>
                <div><i class="fa-solid fa-calendar-days"></i> June 13, 2019</div>
              </div>

            </div>
            <div className='s-b-c-img'><img title="blog-img" src={img} alt='bike-servicing' /></div>
            <div className='s-b-c-para'>
              <h2 className='s-b-c-p-h'>Keeping bike in Garage (not using) Due to CORONA Lock Down? Here are the tips from Garage on Call experts to Keep your Bike Healthy Start the bike once in two weeks and take it around for a short drive at-least 1-2 kms by changing gears, revving in higher RPMs etc. </h2>
             
              
              

            </div>

          </div>


        </div>


        <div><TopBlog /></div>





      </div>


    </div>


<Footer />
    
</div>
    
      
    </div>
  )
}

export default BlogCorona