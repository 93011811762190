import React, { useState } from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import BookingComponent from '../../components/user/BookingComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';
import OfferSlider from '../../components/user/OfferSlider'
import LocationComponent from '../../components/user/LocationComponent'
import { Link } from 'react-router-dom'
import BookingForm from '../../components/user/BookingForm'

const BikePage = () => {
  const [form, setForm] = useState(false);

    
  return (
    <div>
      {form === true ? <BookingForm setForm={setForm} /> : ""}

         <Helmet>
        <title>Book Online Bike Service and Repairing</title>
        <meta name="description" content="Experience hassle-free bike maintenance and repairs right at your doorstep! Book your service today for ultimate convenience and peace of mind." />
      </Helmet>
        
        <Navbar />

      <OfferSlider/>


        <BookingComponent tag="h1"/>


      
      {/* ----------------------------- general service start -------------------------- */}

      <div className="area-location-g-service">
        <div className="area-location-g-service-content">
          <h2 className="area-location-g-service-heading">
            Routine Bike Service <span>Check-list</span> 
          </h2>
          <div className="area-location-g-service-text">
            From engine work to parts replacement, wiring faults, lock/unlock
            issues, and wheel problems,
            <br /> we handle all major repairs right at your location for your convenience.
          </div>
          <div className="area-location-g-service-col">
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Engine Oil Change (Price Extra)</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Oil Filter Clean/Replace (if Replace Charges)</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Air Filter Clean/Replace (if Replace Charges)</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Spark Plug Clean/Replace (if Replace Charges)</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Tightening of Screws, Bolts & Nuts</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Average and Performance Check-up</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Brakes – Front & Rear Adjust</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Driven Chain Basic Cleaning</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Carburettor Basic Check-up</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Minor Electrical Check-up</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Battery General Check-up</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Basic Engine Inspection</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Basic Fork Inspection</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Basic Hand Cleaning</span>
            </div>
            <div className="area-location-g-service-col-list">
              <span className="area-location-g-service-col-list-icon">
                &#10095;&#10095;
              </span>
              <span>Free Pick and Drop</span>
            </div>



          </div>

            <div className="area-location-g-service-price-cols">
              <div className="area-location-g-service-price-list">
                <div className="area-location-g-service-price-list-heading-1">
                  Below 125 CC
                </div>
                <div className="area-location-g-service-price-list-heading-2">
                  Rs. 299/-
                </div>
                <div className="area-location-g-service-price-list-button">
                  <Link title="doorstep bike service at home" 
                    onClick={() => {
                      setForm(true);
                    }}
                  >
                    Book Now
                  </Link>
                </div>
              </div>

              <div className="area-location-g-service-price-list">
                <div className="area-location-g-service-price-list-heading-1">
                  125 CC to 199 CC
                </div>
                <div className="area-location-g-service-price-list-heading-2">
                  Rs. 399/-
                </div>
                <div className="area-location-g-service-price-list-button">
                  <Link title="doorstep bike service at home" 
                    onClick={() => {
                      setForm(true);
                    }}
                  >
                    Book Now
                  </Link>
                </div>
              </div>

              <div className="area-location-g-service-price-list">
                <div className="area-location-g-service-price-list-heading-1">
                  200 CC to 299 CC
                </div>
                <div className="area-location-g-service-price-list-heading-2">
                  Rs. 499/-
                </div>
                <div className="area-location-g-service-price-list-button">
                  <Link title="doorstep bike service at home" 
                    onClick={() => {
                      setForm(true);
                    }}
                  >
                    Book Now
                  </Link>
                </div>
              </div>

              <div className="area-location-g-service-price-list">
                <div className="area-location-g-service-price-list-heading-1">
                  300 CC to 350 CC
                </div>
                <div className="area-location-g-service-price-list-heading-2">
                  Rs. 599/-
                </div>
                <div className="area-location-g-service-price-list-button">
                  <Link title="doorstep bike service at home" 
                    onClick={() => {
                      setForm(true);
                    }}
                  >
                    Book Now
                  </Link>
                </div>
              </div>

              <div className="area-location-g-service-price-list">
                <div className="area-location-g-service-price-list-heading-1">
                  Above 350 CC
                </div>
                <div className="area-location-g-service-price-list-heading-2">
                  Rs. 999/-
                </div>
                <div className="area-location-g-service-price-list-button">
                  <Link title="doorstep bike service at home" 
                    onClick={() => {
                      setForm(true);
                    }}
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
            
        </div>
      </div>

      {/* ----------------------------- general service end -------------------------- */}

<LocationComponent/>

      

      <div  className="bike-brand-location">
      <div className="landing-page-location-heading">
          Bike <span>Brands </span>We Service at <span>Home</span> by <span>Experts</span>
        </div>

<div>
<div className="bike-brand-location-content">
  
  <Link title="doorstep bike service at home"  to="/bike/tvs">TVS</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/bajaj">Bajaj</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/royal-enfield">Royal Enfield</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/yamaha">Yamaha</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/honda">Honda</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/hero">Hero</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/suzuki">Suzuki</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/ktm">KTM</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/jawa">Jawa</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/harley-davidson">Harley Davidson</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/ducati">Ducati</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/kawasaki">Kawasaki</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/benelli">Benelli</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/triumph">Triumph</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/indian">Indian</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/bmw">BMW</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/aprilia">Aprilia</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/yezdi">Yezdi</Link> &nbsp; / &nbsp;
  <Link title="doorstep bike service at home"  to="/bike/husqvarna">Husqvarna</Link>


</div>

</div>

</div>


        <Footer />
        <ButtonComponent />

    </div>
  )
}

export default BikePage