import React from 'react'
import img from '../../../images/blogs/doorstep-bike-service.png'
import Footer from '../../user/Footer'
import TopBlog from '../TopBlog'
import BlogSeo from './BlogSeo'
import BlogNavbar from '../BlogNavbar'

const BlogServiceHom = () => {
    return (
        <div>

<BlogSeo/>


            <div>
                <BlogNavbar />

                <div className='single-blog-main'>

                    <div className='single-blog-cols'>

                        <div className='s-b-col'>

                            <div className='s-b-col-1'>
                                <div className='s-b-c-head'>
                                    <h1 className='s-b-c-h-h-1'>Bike servicing at home</h1>
                                    <div className='s-b-c-h-h-2'>
                                        <div><i class="fa-solid fa-user"></i> ADMIN</div>
                                        <div><i class="fa-solid fa-calendar-days"></i> May 13, 2019</div>
                                    </div>

                                </div>
                                <div className='s-b-c-img'><img  title="blog-img" src={img} alt='doorstep-bike-service' /></div>
                                <div className='s-b-c-para'>
                                    <h2 className='s-b-c-p-h'>
                                        Bike servicing at home
                                    </h2>

                                    <div className='s-b-c-p-t'>
                                        Bike servicing at home
                                        is highly demanded service in metro cities like Noida, Ghaziabad, Faridabad, Gurgaon and Delhi-NCR.
                                    </div>
                                    <div className='s-b-c-p-t'>
                                        You can book a experienced mechanic through our website our mechanic will reach at your home or office with all tools and parts to service or repair your bike.
                                    </div>
                                    <div className='s-b-c-p-t'>
                                        We provide trusted and skilled bike engineer to service your bike at your home in Noida, Ghaziabad, Faridabad, Gurgaon and Delhi-NCR.
                                    </div>
                                    <div className='s-b-c-p-t'>
                                    We bring Garage at your home for your bike checkup and servicing.


                                    </div>
                                    <div className='s-b-c-p-t'>
                                    For all types of bike, we will come to your home or office with all bike tools and equipment to take care of your bikes  Garage on Call offer online booking services for any kind of bike problems and repairing at customer preferred location whether it’s home or office in Noida, Greater Noida, Delhi and Gurgaon. Choose a well qualified and experienced mechanic through our portal we send mechanic at your home to repair any kind of bike related problem or regular bike service at home. we provide doorstep garage services for any type of bike servicing and repairing across Delhi, Noida, Gr Noida west and Gr. Noida
                                    </div>




                                </div>

                            </div>

                           
                           

                        </div>


                       
       <div><TopBlog /></div>
                       



                    </div>


                </div>


                <Footer />

            </div>
        </div>
    )
}

export default BlogServiceHom