import React from 'react'
import AdminBookingsComponent from '../../components/admin/AdminBookingsComponent'

const AdminBookingPage = () => {
  return (
    <div>
        <AdminBookingsComponent/>
    </div>
  )
}

export default AdminBookingPage