import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogFixComponent from "./BlogFixComponent";
import blogData from "../blogs/DataBlog.json";
// import bg from "../../images/blog-bg.jpeg";

const BlogPageComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = blogData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(blogData.length / recordPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const prevPage = (e) => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const changeCPage = (e) => {
    setCurrentPage(e);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const nextPage = (e) => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      {/* <br /><br /><br /> */}
      {currentPage === 1 ? (
        <div className="our-blog-header">
          <div className="our-blog-header-bg">
            <div className="our-blog-header-content">
              <div>
                <h1 className="our-blog-header-heading">
                  Read Our Latest <span>Blog</span> and <span>Articles</span>
                </h1>
                <div  className="our-blog-header-text">and Get Updated</div>
              </div>
          </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="our-blog-page">
        {
          currentPage === 1?""
         : <div className="our-blog-page-heading">
          <span>
            Our Latest <span>Blog</span> and <span>Articles</span>
          </span>
        </div>
        }

        <div className="our-blog-page-main">
          <div className="our-blog-page-col">
            {/* ------------------------------------------------------------ */}

            {records.map((e) => (
              <div className="our-blog-page-blog">
                <div className="our-blog-page-blog-img">
                  <img src={`${e.img}`} alt={`${e.category} - Blog Img`}  title={e.title}/>
                  {/* <div className="our-blog-page-blog-heading">{e.category}</div> */}
                </div>

                <div className="our-blog-page-blog-content">
                  <h2 className="our-blog-page-blog-heading">{e.title}</h2>
                  <div className="our-blog-page-blog-head">
                    <span> {e.date}</span>
                    <span>ADMIN</span>
                  </div>
                  <div className="our-blog-page-blog-text">{e.text}</div>
                  <div className="our-blog-page-blog-button">
                    <Link title={e.title} to={e.link}>Read more...</Link>
                  </div>
                </div>
              </div>
            ))}

            {/* ------------------------------------------------------------ */}

            {/* ------------------------------------------------------------------------- */}

            <div className="blog-component-col-2-pagination">
              <div className="blog-component-col-2-pagination-buttons ">
                <div
                  className={`blog-component-col-2-pagination-btn ${
                    currentPage === 1
                      ? "blog-component-col-2-pagination-btn-disable"
                      : ""
                  }`}
                  onClick={prevPage}
                >
                  <i className="fa-solid fa-angle-left"></i>
                </div>
                {numbers.map((n, i) => (
                  <div
                    className={`blog-component-col-2-pagination-btn ${
                      currentPage === n
                        ? "blog-component-col-2-pagination-btn-active"
                        : ""
                    }`}
                    key={i}
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </div>
                ))}

                <div
                  className={`blog-component-col-2-pagination-btn ${
                    currentPage === numbers.length
                      ? "blog-component-col-2-pagination-btn-disable"
                      : ""
                  }`}
                  onClick={nextPage}
                >
                  <i className="fa-solid fa-angle-right"></i>
                </div>
              </div>
            </div>

            {/* ------------------------------------------------------------------------- */}
          </div>
          <BlogFixComponent />
        </div>
      </div>
    </div>
  );
};

export default BlogPageComponent;
