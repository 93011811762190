import './App.css';
import './css/user/Navbar.css'
import './css/user/Nav.css'
import './css/user/Footer.css'
import './css/user/Contact.css'
import './css/user/BookingPage.css'
import './css/user/Price.css'
import './css/user/BlogPage.css'
import './css/user/AboutPage.css'
import './css/user/TeamPage.css'
import './css/user/TestimonialPage.css'
import './css/user/LocationPage.css'
import './css/user/ServicePage.css'
import './css/user/LandingPage.css'
// import './css/other/Other.css'
import './css/user/Button.css'
import './css/user/SingleBlog.css'
import './css/user/PricePageForm.css'
import './css/user/AreaLocationPage.css'
import './css/user/BikeBrandPage.css'
import './css/user/Sitemap.css'
import './css/user/ErrorPage.css'
import './css/user/RegisterAsAPartner.css'
import './css/user/KeyWords.css'
import './css/user/SingleTestimonial.css'
import './css/user/FaqComponent.css'
import './css/user/ClientSaysComponent.css'
import './css/user/CategoryComponent.css'
import './css/user/TrustedBrandsComponents.css'
import './css/user/OurBenefitsComponent.css'
import './css/user/LatestPostComponent.css'
import './css/user/HeaderTopComponent.css'
import './css/user/NewTestimonialComponent.css'
import './css/user/PackagePriceComponent.css'
import './css/form/NumberForm.css'
import './css/other/Animation.css'
import './css/admin/AdminHome.css'
import './css/admin/AdminNav.css'
import './css/admin/AdminLogin.css'
import './css/admin/AdminBooking.css'
import './css/admin/AdminUpdatePopup.css'
import './css/blog/OurBlogPage.css'


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/user/Home';
import BookingPage from './pages/user/BookingPage';
import ContactPage from './pages/user/ContactPage';
import PricePage from './pages/user/PricePage';
import AboutPage from './pages/user/AboutPage';
import BlogPage from './pages/user/BlogPage';
import TestimonialPage from './pages/user/TestimonialPage';
import OurTeamPage from './pages/user/OurTeamPage';
// import ButtonComponent from './components/user/ButtonComponent';

import ScrollButton from './components/user/ScrollButton';
import LocationPage from './pages/user/LocationPage';
import TermConditionPage from './pages/user/TermConditionPage';
import PrivacyPolicy from './pages/user/PrivacyPolicy';
import ServicePage from './pages/user/ServicePage';
import BlogServicing from './components/blogs/SingleBlogs/BlogServicing';
import BlogBenefits from './components/blogs/SingleBlogs/BlogBenefits';
import BlogTips from './components/blogs/SingleBlogs/BlogTips';
import BlogCorona from './components/blogs/SingleBlogs/BlogCorona';
import BookedPage from './pages/user/BookedPage';
import ContactMessagePage from './pages/user/ContactMessagePage';
// import LandingPage from './pages/user/LandingPage';
import ErrorPage from './pages/user/ErrorPage';
// import AreaLocationPage from './pages/user/AreaLocationPage';
// import BikeBrandPage from './pages/user/BikeBrandPage';
import ScrollToTop from './components/other/ScrollToTop';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import AdminHomePage from './pages/admin/AdminHomePage';
// import UrlParameters from './pages/user/UrlParameters';
import SiteMapPage from './pages/user/SiteMapPage';
import RegisterAsAPartner from './pages/user/RegisterAsAPartner';
import BlogDoorstepBikeServicing from './components/blogs/SingleBlogs/BlogDoorstepBikeServicing';
import BlogServiceHom from './components/blogs/SingleBlogs/BlogServiceHom';
import BlogGarageOnCall from './components/blogs/SingleBlogs/BlogGarageOnCall';
import AdminBookingPage from './pages/admin/AdminBookingPage';
import AdminInquiryPage from './pages/admin/AdminInquiryPage';
import AdminContactUs from './pages/admin/AdminContactUs';
// import AreaLocationPage from './pages/user/AreaLocationPage';
import RegularMotorcycleServicing from './components/blogs/SingleBlogs/RegularMotorcycleServicing';
import RightTwoWheelerForYourNeeds from './components/blogs/SingleBlogs/RightTwoWheelerForYourNeeds';
import IncreasingLifespanYourScooter from './components/blogs/SingleBlogs/IncreasingLifespanYourScooter';
import MotorcycleMaintenanceHome from './components/blogs/SingleBlogs/MotorcycleMaintenanceHome';
import EssentialSafetyGearMotorcyclists from './components/blogs/SingleBlogs/EssentialSafetyGearMotorcyclists';
import BestBeginnerMotorcycles from './components/blogs/SingleBlogs/BestBeginnerMotorcycles';
import SingleTestimonialAnkit from './pages/other/SingleTestimonialAnkit';
import SingleTestimonialMohit from './pages/other/SingleTestimonialMohit';
import SingleTestimonialRajiv from './pages/other/SingleTestimonialRajiv';
import CheckingAndAdjustingTirePressure from './components/blogs/SingleBlogs/CheckingAndAdjustingTirePressure';
import TipsForTwoWheelers from './components/blogs/SingleBlogs/TipsForTwoWheelers';
import MotorcyclesFluidLevels from './components/blogs/SingleBlogs/MotorcyclesFluidLevels';
import CleanAndLubricateYourBikeChain from './components/blogs/SingleBlogs/CleanAndLubricateYourBikeChain';
import ImportanceOfRegularTuneUps from './components/blogs/SingleBlogs/ImportanceOfRegularTuneUps';
import KeepingYourMotorcycleClean from './components/blogs/SingleBlogs/KeepingYourMotorcycleClean';
import MotorcycleBrakeMaintenance from './components/blogs/SingleBlogs/MotorcycleBrakeMaintenance';
import MotorcycleElectricalSystemMaintenance from './components/blogs/SingleBlogs/MotorcycleElectricalSystemMaintenance';
import PreventativeMaintenanceChecklist from './components/blogs/SingleBlogs/PreventativeMaintenanceChecklist';
import MotorcycleChainMaintenance from './components/blogs/SingleBlogs/MotorcycleChainMaintenance';
import MotorcycleCareAndCleaning from './components/blogs/SingleBlogs/MotorcycleCareAndCleaning';
import BrakeMaintenanceAndInspection from './components/blogs/SingleBlogs/BrakeMaintenanceAndInspection';
import MaintenanceMistakes from './components/blogs/SingleBlogs/MaintenanceMistakes';
import EngineIssuesInMotorcycles from './components/blogs/SingleBlogs/EngineIssuesInMotorcycles';
import UnderstandingMotorcycleSuspension from './components/blogs/SingleBlogs/UnderstandingMotorcycleSuspension';
import WinterizingYourBike from './components/blogs/SingleBlogs/WinterizingYourBike';
import SignsOfWornOutPiston from './components/blogs/SingleBlogs/SignsOfWornOutPiston';
import ChangeEngineOil from './components/blogs/SingleBlogs/ChangeEngineOil';
import MainComponentsOfBikeEngine from './components/blogs/SingleBlogs/MainComponentsOfBikeEngine';
import MaintainCoolingSystem from './components/blogs/SingleBlogs/MaintainCoolingSystem';
import SyntheticBikeEngine from './components/blogs/SingleBlogs/SyntheticBikeEngine';
import FuelInjectedEngine from './components/blogs/SingleBlogs/FuelInjectedEngine';
import CylinderHeadBikeEngine from './components/blogs/SingleBlogs/CylinderHeadBikeEngine';
import ImprovePerformance from './components/blogs/SingleBlogs/ImprovePerformance';
import AdjustValveClearance from './components/blogs/SingleBlogs/AdjustValveClearance';
import EngineOverheating from './components/blogs/SingleBlogs/EngineOverheating';
import ReplaceSparkPlug from './components/blogs/SingleBlogs/ReplaceSparkPlug';
import FunctionOfCrankshaft from './components/blogs/SingleBlogs/FunctionOfCrankshaft';
import EngineOilChanges from './components/blogs/SingleBlogs/EngineOilChanges';
import MaintainAirFilter from './components/blogs/SingleBlogs/MaintainAirFilter';
import TimingChainFunction from './components/blogs/SingleBlogs/TimingChainFunction';
import AutomaticTransmission from './components/blogs/SingleBlogs/AutomaticTransmission';
import LiquidCooled from './components/blogs/SingleBlogs/LiquidCooled';
import FailingBikeEngine from './components/blogs/SingleBlogs/FailingBikeEngine';
import CheckOilLevel from './components/blogs/SingleBlogs/CheckOilLevel';
import BasicTuneUp from './components/blogs/SingleBlogs/BasicTuneUp';
import ExhaustValve from './components/blogs/SingleBlogs/ExhaustValve';
import ReplaceFuelFilter from './components/blogs/SingleBlogs/ReplaceFuelFilter';
import IgnitionSystem from './components/blogs/SingleBlogs/IgnitionSystem';
import EngineMisfiring from './components/blogs/SingleBlogs/EngineMisfiring';
import FunctionOfPistonRings from './components/blogs/SingleBlogs/FunctionOfPistonRings';
import MaintainDriveBelt from './components/blogs/SingleBlogs/MaintainDriveBelt';
import TurbochargerWork from './components/blogs/SingleBlogs/TurbochargerWork';
import ReplaceTimingBelt from './components/blogs/SingleBlogs/ReplaceTimingBelt';
import EngineCoolingFan from './components/blogs/SingleBlogs/EngineCoolingFan';
import FourValveEngine from './components/blogs/SingleBlogs/FourValveEngine';
import ThrottleBodyInBikeEngine from './components/blogs/SingleBlogs/ThrottleBodyInBikeEngine';
import AlternatorInBike from './components/blogs/SingleBlogs/AlternatorInBike';
import TroubleshootNoisyBike from './components/blogs/SingleBlogs/TroubleshootNoisyBike';
import MaintainGearbox from './components/blogs/SingleBlogs/MaintainGearbox';
import CloggedFuelInjector from './components/blogs/SingleBlogs/CloggedFuelInjector';
import VTwinEngine from './components/blogs/SingleBlogs/VTwinEngine';
import ConvenienceOfDoorstep from './components/blogs/SingleBlogs/ConvenienceOfDoorstep';
import MaintenanceCrucial from './components/blogs/SingleBlogs/MaintenanceCrucial';
import NeedsImmediateAttention from './components/blogs/SingleBlogs/NeedsImmediateAttention';
import BikePage from './pages/user/BikePage';
import MaintainingRoyalEnfield from './components/blogs/SingleBlogs/MaintainingRoyalEnfield';
// import OtherPage from './pages/other/OtherPage';
// import OtherNewPage from './pages/other/OtherNewPage';
import LocationBikeModelPage from './pages/user/LocationBikeModelPage';
import RoutineServicesPage from './pages/user/RoutineServicesPage';
import FilterBlogPage from './pages/user/FilterBlogPage';
import BlogErrorPage from './pages/user/BlogErrorPage';
import RightDoorstepServiceProvider from './components/blogs/SingleBlogs/RightDoorstepServiceProvider';
import ElectricBikeMaintenanceSchedule from './components/blogs/SingleBlogs/ElectricBikeMaintenanceSchedule';
import MaintainingElectricScooter from './components/blogs/SingleBlogs/MaintainingElectricScooter';
import ExtendEVBatteryLife from './components/blogs/SingleBlogs/ExtendEVBatteryLife';
import IdentifyFixBattery from './components/blogs/SingleBlogs/IdentifyFixBattery';
import ElectricBikeMotorRunningSmoothly from './components/blogs/SingleBlogs/ElectricBikeMotorRunningSmoothly';
import MaintainOptimalPerformance from './components/blogs/SingleBlogs/MaintainOptimalPerformance';
import PreRideSafetyCheck from './components/blogs/SingleBlogs/PreRideSafetyCheck';
import StoreElectricScooter from './components/blogs/SingleBlogs/StoreElectricScooter';
import SafelyTransportElectricBike from './components/blogs/SingleBlogs/SafelyTransportElectricBike';
import CommonElectricBikeIssues from './components/blogs/SingleBlogs/CommonElectricBikeIssues';
import CleaningElectricScooter from './components/blogs/SingleBlogs/CleaningElectricScooter';
import RegularTireChecks from './components/blogs/SingleBlogs/RegularTireChecks';
import ProsConsElectricScooter from './components/blogs/SingleBlogs/ProsConsElectricScooter';
import GuideRoutineElectricBike from './components/blogs/SingleBlogs/GuideRoutineElectricBike';
import TopToolsElectricBike from './components/blogs/SingleBlogs/TopToolsElectricBike';
import UpgradingYourElectricBike from './components/blogs/SingleBlogs/UpgradingYourElectricBike';
import ElectricScooterTires from './components/blogs/SingleBlogs/ElectricScooterTires';
import TipsProtectingElectricBike from './components/blogs/SingleBlogs/TipsProtectingElectricBike';
// import ScrollToTop from './components/other/ScrollToTop';


function App() {
  return (
    <div className="web-app">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home />}></Route>

          <Route path='/bike' element={<BikePage />}></Route>

          {/* <Route path='/:id' element={<AreaLocationPage />}></Route> */}
          {/* <Route path='/:id' element={<UrlParameters />}></Route> */}
          {/* <Route path='/harley-davidson' element={<BikeBrandPage />}></Route> */}
          <Route path='/book-bike-service' element={<BookingPage />}></Route>
          <Route path='/contact-us' element={<ContactPage />}></Route>

          <Route path='/our-prices' element={<PricePage />}></Route>
          <Route path='/bike/routine-service' element={<RoutineServicesPage type="At-Home" />}></Route>
          <Route path='/bike/rsa-service' element={<RoutineServicesPage type="Road Side" />}></Route>
          
          <Route path='/about-us' element={<AboutPage />}></Route>
          <Route path='/our-team' element={<OurTeamPage />}></Route>
          <Route path='/testimonials' element={<TestimonialPage />}></Route>
          <Route path='/category/blog' element={<BlogPage />}></Route>
          <Route path='/our-locations' element={<LocationPage />}></Route>
          <Route path='/disclaimer' element={<TermConditionPage />}></Route>
          {/* <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route> */}
          <Route path='/privacy-policy-2' element={<PrivacyPolicy />}></Route>
          <Route path='/our-services' element={<ServicePage />}></Route>
          <Route path='/category/services' element={<ServicePage />}></Route>
          <Route path='/thanks' element={<BookedPage />}></Route>
          <Route path='/booking-confirm' element={<BookedPage />}></Route>
          <Route path='/contact-confirm' element={<ContactMessagePage />}></Route>

          <Route path='/testimonials/robert-smith' element={<SingleTestimonialAnkit />}></Route>
          <Route path='/testimonials/334' element={<SingleTestimonialMohit />}></Route>
          <Route path='/testimonials/arun' element={<SingleTestimonialRajiv />}></Route>


          <Route path='/sitemap' element={<SiteMapPage />}></Route>
          <Route path='/register-as-a-partner' element={<RegisterAsAPartner />}></Route>

          {/* <Route path='/welcome' element={<LandingPage />}></Route> */}


{/* ------------------------ blogs pages --------------------------------------------------------------- */}
          <Route path='/blog' element={<BlogPage />}></Route>
          <Route path='/blog/category/bike' element={<FilterBlogPage type="bike"/>}></Route>
          <Route path='/blog/category/scooty' element={<FilterBlogPage type="scooty"/>}></Route>
          <Route path='/blog/category/ev' element={<FilterBlogPage type="Electric Vehicle"/>}></Route>

          <Route path='/blog/*' element={<BlogErrorPage/>}></Route>


          <Route path='/blog/porsche-cayenne-coupe-launched-in-india' element={<BlogServicing/>}></Route>
          <Route path='/blog/renault-sells-over-18000-units-with-the-triber-mpv-in-india' element={<BlogBenefits/>}></Route>
          <Route path='/blog/tata-hexa-offering-benefits-upto-₹-2-2-lakh' element={<BlogTips />}></Route>
          <Route path='/blog/corona-blog' element={<BlogCorona />}></Route>
          <Route path='/blog/doorstep-bike-servicing-and-repairing' element={<BlogDoorstepBikeServicing />}></Route>
          <Route path='/blog/bike-servicing-at-home' element={<BlogServiceHom />}></Route>
          <Route path='/blog/no-local-garage-call-garage-on-call' element={<BlogGarageOnCall />}></Route>
          <Route path='/blog/regular-motorcycle-servicing' element={<RegularMotorcycleServicing />}></Route>
          <Route path='/blog/right-two-Wheeler-for-your-needs' element={<RightTwoWheelerForYourNeeds />}></Route>
          <Route path='/blog/tips-for-increasing-the-lifespan-your-scooter' element={<IncreasingLifespanYourScooter />}></Route>
          <Route path='/blog/basic-motorcycle-maintenance-at-home' element={<MotorcycleMaintenanceHome />}></Route>
          <Route path='/blog/essential-safety-gear-for-motorcyclists' element={<EssentialSafetyGearMotorcyclists />}></Route>
          <Route path='/blog/top-10-best-beginner-motorcycles-for-new-riders' element={<BestBeginnerMotorcycles />}></Route>
          <Route path='/blog/checking-and-adjusting-tire-pressure' element={<CheckingAndAdjustingTirePressure />}></Route>
          <Route path='/blog/maintenance-tips-for-two-wheelers' element={<TipsForTwoWheelers />}></Route>
          <Route path='/blog/check-your-motorcycles-fluid-levels' element={<MotorcyclesFluidLevels />}></Route>
          <Route path='/blog/clean-and-lubricate-your-bike-chain' element={<CleanAndLubricateYourBikeChain />}></Route>
          <Route path='/blog/importance-of-regular-tune-ups' element={<ImportanceOfRegularTuneUps />}></Route>
          <Route path='/blog/keeping-your-motorcycle-clean' element={<KeepingYourMotorcycleClean />}></Route>
          <Route path='/blog/motorcyclebrake-maintenance' element={<MotorcycleBrakeMaintenance />}></Route>
          <Route path='/blog/motorcycle-electrical-system-maintenance' element={<MotorcycleElectricalSystemMaintenance />}></Route>
          <Route path='/blog/preventative-maintenance-checklist' element={<PreventativeMaintenanceChecklist />}></Route>
          <Route path='/blog/motorcycle-chain-maintenance' element={<MotorcycleChainMaintenance/>}></Route>
          <Route path='/blog/motorcycle-care-and-cleaning' element={<MotorcycleCareAndCleaning/>}></Route>
          <Route path='/blog/brake-maintenance-and-inspection' element={<BrakeMaintenanceAndInspection/>}></Route>
          <Route path='/blog/maintenanc-mistakes' element={<MaintenanceMistakes/>}></Route>
          <Route path='/blog/engine-issues-in-motorcycles' element={<EngineIssuesInMotorcycles/>}></Route>
          <Route path='/blog/understanding-motorcycle-suspension' element={<UnderstandingMotorcycleSuspension/>}></Route>
          <Route path='/blog/winterizing-your-bike' element={<WinterizingYourBike/>}></Route>
          <Route path='/blog/signs-of-worn-out-piston' element={<SignsOfWornOutPiston/>}></Route>
          <Route path='/blog/change-engine-oil' element={<ChangeEngineOil/>}></Route>
          <Route path='/blog/main-components-of-bike-engine' element={<MainComponentsOfBikeEngine/>}></Route>
          <Route path='/blog/maintain-cooling-system' element={<MaintainCoolingSystem/>}></Route>
          <Route path='/blog/benefits-using-synthetic-oil-in-bike-engine' element={<SyntheticBikeEngine/>}></Route>
          <Route path='/blog/fuel-injected-engine-work-in-bikes' element={<FuelInjectedEngine/>}></Route>
          <Route path='/blog/role-of-cylinder-head-in-bike-engine' element={<CylinderHeadBikeEngine/>}></Route>
          <Route path='/blog/improve-performance-of-bike-engine' element={<ImprovePerformance/>}></Route>
          <Route path='/blog/adjust-valve-clearance-on-bike-engine' element={<AdjustValveClearance/>}></Route>
          <Route path='/blog/reasons-for-engine-overheating-in-scooties' element={<EngineOverheating/>}></Route>
          <Route path='/blog/how-do-replace-the-spark-plug-on-scooty' element={<ReplaceSparkPlug/>}></Route>
          <Route path='/blog/function-of-crankshaft-in-bike-engine' element={<FunctionOfCrankshaft/>}></Route>
          <Route path='/blog/importance-of-regular-engine-oil-changes-for-bikes' element={<EngineOilChanges/>}></Route>
          <Route path='/blog/maintain-air-filter-in-scooty' element={<MaintainAirFilter/>}></Route>
          <Route path='/blog/function-of-timing-chain-in-bike-engine' element={<TimingChainFunction/>}></Route>
          <Route path='/blog/automatic-transmission-work-in-scooty' element={<AutomaticTransmission/>}></Route>
          <Route path='/blog/benefits-of-liquid-cooled-engine-for-bikes' element={<LiquidCooled/>}></Route>
          <Route path='/blog/signs-of-failing-bike-engine' element={<FailingBikeEngine/>}></Route>
          <Route path='/blog/how-do-check-oil-level-in-bike-engine' element={<CheckOilLevel/>}></Route>
          <Route path='/blog/how-do-perform-basic-tune-up-on-scooty-engine' element={<BasicTuneUp/>}></Route>
          <Route path='/blog/purpose-of-exhaust-valve-in-bike-engine' element={<ExhaustValve/>}></Route>
          <Route path='/blog/how-do-replace-fuel-filter-on-bike' element={<ReplaceFuelFilter/>}></Route>
          <Route path='/blog/role-of-ignition-system-in-bike-engine' element={<IgnitionSystem/>}></Route>
          <Route path='/blog/common-causes-of-engine-misfiring-in-scooties' element={<EngineMisfiring/>}></Route>
          <Route path='/blog/function-of-piston-rings-in-bike-engine' element={<FunctionOfPistonRings/>}></Route>
          <Route path='/blog/maintain-drive-belt-on-scooty' element={<MaintainDriveBelt/>}></Route>
          <Route path='/blog/turbocharger-work-in-bike-engine' element={<TurbochargerWork/>}></Route>
          <Route path='/blog/replace-timing-belt-on-scooty' element={<ReplaceTimingBelt/>}></Route>
          <Route path='/blog/how-does-bike-engine-cooling-fan-work' element={<EngineCoolingFan/>}></Route>
          <Route path='/blog/four-valve-engine-in-bikes' element={<FourValveEngine/>}></Route>
          <Route path='/blog/purpose-of-throttle-body-in-bike-engine' element={<ThrottleBodyInBikeEngine/>}></Route>
          <Route path='/blog/function-of-alternatorin-bike-engine' element={<AlternatorInBike/>}></Route>
          <Route path='/blog/how-do-troubleshoot-noisy-bike-engine' element={<TroubleshootNoisyBike/>}></Route>
          <Route path='/blog/how-do-maintain-gearbox-on-scooty' element={<MaintainGearbox/>}></Route>
          <Route path='/blog/signs-of-clogged-fuel-injector-in-bikes' element={<CloggedFuelInjector/>}></Route>
          <Route path='/blog/benefits-of-V-twin-engine-in-bikes' element={<VTwinEngine/>}></Route>
          <Route path='/blog/convenience-of-doorstep-two-wheeler-servicing' element={<ConvenienceOfDoorstep/>}></Route>
          <Route path='/blog/why-regular-maintenance-is-crucial-for-your-bike-and-scooty' element={<MaintenanceCrucial/>}></Route>
          <Route path='/blog/common-signs-your-bike-or-scooty-needs-immediate-attention' element={<NeedsImmediateAttention/>}></Route>
          <Route path='/blog/top-10-tips-for-maintaining-your-royal-enfield-bike' element={<MaintainingRoyalEnfield/>}></Route>
          <Route path='/blog/how-to-choose-the-right-doorstep-service-provider-for-your-two-wheeler' element={<RightDoorstepServiceProvider/>}></Route>

{/* ev blogs ------------------------------------- */}
          <Route path='/blog/electric-bike-maintenance-schedule-what-to-do-and-when' element={<ElectricBikeMaintenanceSchedule/>}></Route>
          <Route path='/blog/essential-tips-for-maintaining-your-electric-scooter-brakes' element={<MaintainingElectricScooter/>}></Route>
          <Route path='/blog/how-to-extend-the-battery-life-of-your-electric-scooter' element={<ExtendEVBatteryLife/>}></Route>
          <Route path='/blog/how-to-identify-and-fix-battery-charging-problems-in-electric-scooters' element={<IdentifyFixBattery/>}></Route>
          <Route path='/blog/how-to-keep-your-electric-bike-motor-running-smoothly' element={<ElectricBikeMotorRunningSmoothly/>}></Route>
          <Route path='/blog/how-to-maintain-optimal-performance-of-your-electri-scooter-chain-and-gears' element={<MaintainOptimalPerformance/>}></Route>
          <Route path='/blog/how-to-perform-a-pre-ride-safety-check-on-your-electric-bike' element={<PreRideSafetyCheck/>}></Route>
          <Route path='/blog/how-to-properly-store-your-electric-scooter-during-off-season' element={<StoreElectricScooter/>}></Route>
          <Route path='/blog/how-to-safely-transport-your-electric-bike-or-scooter' element={<SafelyTransportElectricBike/>}></Route>
          <Route path='/blog/how-to-troubleshoot-common-electric-bike-issues' element={<CommonElectricBikeIssues/>}></Route>
          <Route path='/blog/step-by-step-guide-to-cleaning-your-electric-scooter' element={<CleaningElectricScooter/>}></Route>
          <Route path='/blog/the-importance-of-regular-tire-checks-and-maintenance' element={<RegularTireChecks/>}></Route>
          <Route path='/blog/the-pros-and-cons-of-diy-vs-professional-electric-scooter-maintenance' element={<ProsConsElectricScooter/>}></Route>
          <Route path='/blog/the-ultimate-guide-to-routine-electric-bike-maintenance' element={<GuideRoutineElectricBike/>}></Route>
          <Route path='/blog/top-10-tools-every-electric-bike-owner-should-have' element={<TopToolsElectricBike/>}></Route>
          <Route path='/blog/upgrading-your-electric-bike-components-what-you-need-to-know' element={<UpgradingYourElectricBike/>}></Route>
          <Route path='/blog/when-to-replace-your-electric-scooter-tires-and-how-to-do-it' element={<ElectricScooterTires/>}></Route>
          <Route path='/blog/tips-for-protecting-you-electric-bike-from-weather-damage' element={<TipsProtectingElectricBike/>}></Route>


          





{/* ------------------------ Bike path --------------------------- */}
          {/* <Route path='/hello' element={<BikeBrandPage bike="hehe"/>}></Route> */}

{/* <Route path='/bike/tVS' element={<BikeBrandPage bike="TVS"/>}></Route>
<Route path='/bike/bajaj' element={<BikeBrandPage bike="Bajaj"/>}></Route>
<Route path='/bike/royal-enfield' element={<BikeBrandPage bike="Royal Enfield"/>}></Route>
<Route path='/bike/bullet' element={<BikeBrandPage bike="Bullet"/>}></Route>
<Route path='/bike/yamaha' element={<BikeBrandPage bike="Yamaha"/>}></Route>
<Route path='/bike/honda' element={<BikeBrandPage bike="Honda"/>}></Route>
<Route path='/bike/hero' element={<BikeBrandPage bike="Hero"/>}></Route>
<Route path='/bike/suzuki' element={<BikeBrandPage bike="Suzuki"/>}></Route>
<Route path='/bike/ktm' element={<BikeBrandPage bike="KTM"/>}></Route>
<Route path='/bike/jawa' element={<BikeBrandPage bike="Jawa"/>}></Route>
<Route path='/bike/harley-davidson' element={<BikeBrandPage bike="Harley Davidson"/>}></Route>
<Route path='/bike/ducati' element={<BikeBrandPage bike="Ducati"/>}></Route>
<Route path='/bike/kawasaki' element={<BikeBrandPage bike="Kawasaki"/>}></Route>
<Route path='/bike/benelli' element={<BikeBrandPage bike="Benelli"/>}></Route>
<Route path='/bike/triumph' element={<BikeBrandPage bike="Triumph"/>}></Route>
<Route path='/bike/indian' element={<BikeBrandPage bike="Indian"/>}></Route>
<Route path='/bike/bmw' element={<BikeBrandPage bike="BMW"/>}></Route>
<Route path='/bike/aprilia' element={<BikeBrandPage bike="Aprilia"/>}></Route>
<Route path='/bike/yezdi' element={<BikeBrandPage bike="Yezdi"/>}></Route>
<Route path='/bike/husqvarna' element={<BikeBrandPage bike="Husqvarna"/>}></Route> */}
          


<Route path='/bike/tVS' element={<LocationBikeModelPage bike="TVS"/>}></Route>
<Route path='/bike/bajaj' element={<LocationBikeModelPage bike="Bajaj"/>}></Route>
<Route path='/bike/royal-enfield' element={<LocationBikeModelPage bike="Royal Enfield"/>}></Route>
<Route path='/bike/bullet' element={<LocationBikeModelPage model="Bullet"/>}></Route>
<Route path='/bike/yamaha' element={<LocationBikeModelPage bike="Yamaha"/>}></Route>
<Route path='/bike/honda' element={<LocationBikeModelPage bike="Honda"/>}></Route>
<Route path='/bike/hero' element={<LocationBikeModelPage bike="Hero"/>}></Route>
<Route path='/bike/suzuki' element={<LocationBikeModelPage bike="Suzuki"/>}></Route>
<Route path='/bike/ktm' element={<LocationBikeModelPage bike="KTM"/>}></Route>
<Route path='/bike/jawa' element={<LocationBikeModelPage bike="Jawa"/>}></Route>
<Route path='/bike/harley-davidson' element={<LocationBikeModelPage bike="Harley Davidson"/>}></Route>
<Route path='/bike/ducati' element={<LocationBikeModelPage bike="Ducati"/>}></Route>
<Route path='/bike/kawasaki' element={<LocationBikeModelPage bike="Kawasaki"/>}></Route>
<Route path='/bike/benelli' element={<LocationBikeModelPage bike="Benelli"/>}></Route>
<Route path='/bike/triumph' element={<LocationBikeModelPage bike="Triumph"/>}></Route>
<Route path='/bike/indian' element={<LocationBikeModelPage bike="Indian"/>}></Route>
<Route path='/bike/bmw' element={<LocationBikeModelPage bike="BMW"/>}></Route>
<Route path='/bike/aprilia' element={<LocationBikeModelPage bike="Aprilia"/>}></Route>
<Route path='/bike/yezdi' element={<LocationBikeModelPage bike="Yezdi"/>}></Route>
<Route path='/bike/husqvarna' element={<LocationBikeModelPage bike="Husqvarna"/>}></Route>
          

{/* ------------------------ location path --------------------------- */}


          <Route path='/bike-service/delhi' element={<LocationBikeModelPage location="Delhi" />}></Route>



          <Route path='/bike-service/delhi' element={<LocationBikeModelPage location="Delhi" />}></Route>
          <Route path='/bike-service/noida' element={<LocationBikeModelPage location="Noida" />}></Route>
          <Route path='/bike-service/greater-noida' element={<LocationBikeModelPage location="Greater Noida" />}></Route>
          <Route path='/bike-service/gurugram' element={<LocationBikeModelPage location="Gurugram" />}></Route>
          <Route path='/bike-service/faridabad' element={<LocationBikeModelPage location="Faridabad" />}></Route>
          <Route path='/bike-service/ghaziabad' element={<LocationBikeModelPage location="Ghaziabad" />}></Route>
          <Route path='/bike-service/chandigarh' element={<LocationBikeModelPage location="Chandigarh" />}></Route>
          <Route path='/bike-service/bengaluru' element={<LocationBikeModelPage location="Bengaluru" />}></Route>
          <Route path='/bike-service/pune' element={<LocationBikeModelPage location="Pune" />}></Route>
          <Route path='/bike-service/lucknow' element={<LocationBikeModelPage location="Lucknow" />}></Route>
          <Route path='/bike-service/kolkata' element={<LocationBikeModelPage location="Kolkata" />}></Route>


          <Route path='/bike-repair/delhi' element={<LocationBikeModelPage location="Delhi" />}></Route>
          <Route path='/bike-repair/noida' element={<LocationBikeModelPage location="Noida" />}></Route>
          <Route path='/bike-repair/greater-noida' element={<LocationBikeModelPage location="Greater Noida" />}></Route>
          <Route path='/bike-repair/gurugram' element={<LocationBikeModelPage location="Gurugram" />}></Route>
          <Route path='/bike-repair/faridabad' element={<LocationBikeModelPage location="Faridabad" />}></Route>
          <Route path='/bike-repair/ghaziabad' element={<LocationBikeModelPage location="Ghaziabad" />}></Route>
          <Route path='/bike-repair/chandigarh' element={<LocationBikeModelPage location="Chandigarh" />}></Route>
          <Route path='/bike-repair/bengaluru' element={<LocationBikeModelPage location="Bengaluru" />}></Route>
          <Route path='/bike-repair/pune' element={<LocationBikeModelPage location="Pune" />}></Route>
          <Route path='/bike-repair/lucknow' element={<LocationBikeModelPage location="Lucknow" />}></Route>
          <Route path='/bike-repair/kolkata' element={<LocationBikeModelPage location="Kolkata" />}></Route>


          <Route path='/bike-mechanic/delhi' element={<LocationBikeModelPage location="Delhi" />}></Route>
          <Route path='/bike-mechanic/noida' element={<LocationBikeModelPage location="Noida" />}></Route>
          <Route path='/bike-mechanic/greater-noida' element={<LocationBikeModelPage location="Greater Noida" />}></Route>
          <Route path='/bike-mechanic/gurugram' element={<LocationBikeModelPage location="Gurugram" />}></Route>
          <Route path='/bike-mechanic/faridabad' element={<LocationBikeModelPage location="Faridabad" />}></Route>
          <Route path='/bike-mechanic/ghaziabad' element={<LocationBikeModelPage location="Ghaziabad" />}></Route>
          <Route path='/bike-mechanic/chandigarh' element={<LocationBikeModelPage location="Chandigarh" />}></Route>
          <Route path='/bike-mechanic/bengaluru' element={<LocationBikeModelPage location="Bengaluru" />}></Route>
          <Route path='/bike-mechanic/pune' element={<LocationBikeModelPage location="Pune" />}></Route>
          <Route path='/bike-mechanic/lucknow' element={<LocationBikeModelPage location="Lucknow" />}></Route>
          <Route path='/bike-mechanic/kolkata' element={<LocationBikeModelPage location="Kolkata" />}></Route>



          <Route path='/bike/delhi' element={<LocationBikeModelPage location="Delhi" />}></Route>
          <Route path='/bike/noida' element={<LocationBikeModelPage location="Noida" />}></Route>
          <Route path='/bike/greater-noida' element={<LocationBikeModelPage location="Greater Noida" />}></Route>
          <Route path='/bike/gurugram' element={<LocationBikeModelPage location="Gurugram" />}></Route>
          <Route path='/bike/faridabad' element={<LocationBikeModelPage location="Faridabad" />}></Route>
          <Route path='/bike/ghaziabad' element={<LocationBikeModelPage location="Ghaziabad" />}></Route>
          <Route path='/bike/chandigarh' element={<LocationBikeModelPage location="Chandigarh" />}></Route>
          <Route path='/bike/bengaluru' element={<LocationBikeModelPage location="Bengaluru" />}></Route>
          <Route path='/bike/pune' element={<LocationBikeModelPage location="Pune" />}></Route>
          <Route path='/bike/lucknow' element={<LocationBikeModelPage location="Lucknow" />}></Route>
          <Route path='/bike/kolkata' element={<LocationBikeModelPage location="Kolkata" />}></Route>

                    

          {/* <Route path='/bike/delhi' element={<AreaLocationPage location="Delhi" />}></Route>
          <Route path='/bike/noida' element={<AreaLocationPage location="Noida" />}></Route>
          <Route path='/bike/greater-noida' element={<AreaLocationPage location="Greater Noida" />}></Route>
          <Route path='/bike/gurugram' element={<AreaLocationPage location="Gurugram" />}></Route>
          <Route path='/bike/faridabad' element={<AreaLocationPage location="Faridabad" />}></Route>
          <Route path='/bike/ghaziabad' element={<AreaLocationPage location="Ghaziabad" />}></Route>
          <Route path='/bike/chandigarh' element={<AreaLocationPage location="Chandigarh" />}></Route>
          <Route path='/bike/bengaluru' element={<AreaLocationPage location="Bengaluru" />}></Route>
          <Route path='/bike/pune' element={<AreaLocationPage location="Pune" />}></Route>
          <Route path='/bike/lucknow' element={<AreaLocationPage location="Lucknow" />}></Route>
          <Route path='/bike/kolkata' element={<AreaLocationPage location="Kolkata" />}></Route> */}


          <Route path='/bike/bangalore' element={<LocationBikeModelPage location="Bangalore" />}></Route>
          <Route path='/bike/meerut' element={<LocationBikeModelPage location="Meerut" />}></Route>
          <Route path='/bike/agra' element={<LocationBikeModelPage location="Agra" />}></Route>
          <Route path='/bike/hyderabad' element={<LocationBikeModelPage location="Hyderabad" />}></Route>
          <Route path='/bike/panchkula' element={<LocationBikeModelPage location="Panchkula" />}></Route>
          <Route path='/bike/ambala' element={<LocationBikeModelPage location="Ambala" />}></Route>
          <Route path='/bike/ludhiana' element={<LocationBikeModelPage location="Ludhiana" />}></Route>
          <Route path='/bike/zirakpur' element={<LocationBikeModelPage location="Zirakpur" />}></Route>
          <Route path='/bike/sahibzada-ajit-singh-nagar' element={<LocationBikeModelPage location="Sahibzada Ajit Singh Nagar" />}></Route>
          <Route path='/bike/mohali' element={<LocationBikeModelPage location="Mohali" />}></Route>
          <Route path='/bike/dera-bassi' element={<LocationBikeModelPage location="Dera Bassi" />}></Route>
          <Route path='/bike/kharar' element={<LocationBikeModelPage location="Kharar" />}></Route>
          <Route path='/bike/jalandhar' element={<LocationBikeModelPage location="Jalandhar " />}></Route>
          <Route path='/bike/gurgaon' element={<LocationBikeModelPage location="Gurugram" />}></Route>
          <Route path='/bike/noida-extension' element={<LocationBikeModelPage location="Noida Extension" />}></Route>
          <Route path='/bike/greater-noida-west' element={<LocationBikeModelPage location="Greater Noida West" />}></Route>
          <Route path='/bike/noida/sector-18' element={<LocationBikeModelPage location="Sector-18, Noida" />}></Route>
          <Route path='/bike/greater-noida/sector-36' element={<LocationBikeModelPage location="Sector-36, Greater Noida" />}></Route>
          <Route path='/bike/faridabad/sgm-nagar-sector-48' element={<LocationBikeModelPage location="SGM Nagar Sector-48, Faridabad" />}></Route>
          <Route path='/bike/delhi/kapashera' element={<LocationBikeModelPage location="Kapashera, Delhi" />}></Route>
          <Route path='/bike/ghaziabad/new-vijay-nagar' element={<LocationBikeModelPage location="New Vijay Nagar, Ghaziabad" />}></Route>
          <Route path='/bike/delhi/palam-vihar' element={<LocationBikeModelPage location="Palam Vihar, Delhi" />}></Route>
          <Route path='/bike/gurugram/sector-33' element={<LocationBikeModelPage location="Sector-33, Gurugram" />}></Route>
          <Route path='/bike/gurugram/sector-30' element={<LocationBikeModelPage location="Sector-30, Gurugram" />}></Route>
          <Route path='/bike/gurugram/sector-105' element={<LocationBikeModelPage location="Sector-105, Gurugram" />}></Route>
          <Route path='/bike/faridabad/ballabhgarh' element={<LocationBikeModelPage location="Ballabhgarh, Faridabad" />}></Route>
          <Route path='/bike/ghaziabad/kaushambi' element={<LocationBikeModelPage location="Kaushambi, Ghaziabad" />}></Route>
          <Route path='/bike/south-delhi/lado-sarai' element={<LocationBikeModelPage location="Lado Sarai, South Delhi" />}></Route>
          <Route path='/bike/kanpur' element={<LocationBikeModelPage location="Kanpur" />}></Route>
          <Route path='/bike/kolkata' element={<LocationBikeModelPage location="Kolkata" />}></Route>
          <Route path='/bike/Goa' element={<LocationBikeModelPage location="Goa" />}></Route>
          <Route path='/bike/chandigarh/setor-38' element={<LocationBikeModelPage location="Setor-38, Chandigarh" />}></Route>
          <Route path='/bike/greater-noida-west/supertech-ecovillage-1-Bisrakh' element={<LocationBikeModelPage location="Supertech Ecovillage -1, Bisrakh, Greater Noida West" />}></Route>
          <Route path='/bike/north-delhi/sant-nagar-burari' element={<LocationBikeModelPage location="Sant Nagar Burari, North Delhi" />}></Route>
          <Route path='/bike/east-delhi' element={<LocationBikeModelPage location="East Delhi" />}></Route>
          <Route path='/bike/west-delhi' element={<LocationBikeModelPage location="West Delhi" />}></Route>
          <Route path='/bike/north-delhi' element={<LocationBikeModelPage location="North Delhi" />}></Route>
          <Route path='/bike/south-delhi' element={<LocationBikeModelPage location="South Delhi" />}></Route>


{/* 
          <Route path='/bike/bangalore' element={<AreaLocationPage location="Bangalore" />}></Route>
          <Route path='/bike/meerut' element={<AreaLocationPage location="meerut" />}></Route>
          <Route path='/bike/agra' element={<AreaLocationPage location="agra" />}></Route>
          <Route path='/bike/hyderabad' element={<AreaLocationPage location="hyderabad" />}></Route>
          <Route path='/bike/panchkula' element={<AreaLocationPage location="panchkula" />}></Route>
          <Route path='/bike/ambala' element={<AreaLocationPage location="ambala" />}></Route>
          <Route path='/bike/ludhiana' element={<AreaLocationPage location="ludhiana" />}></Route>
          <Route path='/bike/zirakpur' element={<AreaLocationPage location="zirakpur" />}></Route>
          <Route path='/bike/sahibzada-ajit-singh-nagar' element={<AreaLocationPage location="sahibzada ajit singh nagar" />}></Route>
          <Route path='/bike/mohali' element={<AreaLocationPage location="mohali" />}></Route>
          <Route path='/bike/dera-bassi' element={<AreaLocationPage location="Dera Bassi" />}></Route>
          <Route path='/bike/kharar' element={<AreaLocationPage location="Kharar" />}></Route>
          <Route path='/bike/jalandhar' element={<AreaLocationPage location="Jalandhar " />}></Route>
          <Route path='/bike/gurgaon' element={<AreaLocationPage location="gurugram" />}></Route>
          <Route path='/bike/noida-extension' element={<AreaLocationPage location="Noida Extension" />}></Route>
          <Route path='/bike/greater-noida-west' element={<AreaLocationPage location="greater noida west" />}></Route>
          <Route path='/bike/noida/sector-18' element={<AreaLocationPage location="Sector-18, Noida" />}></Route>
          <Route path='/bike/greater-noida/sector-36' element={<AreaLocationPage location="Sector-36, Greater Noida" />}></Route>
          <Route path='/bike/faridabad/sgm-nagar-sector-48' element={<AreaLocationPage location="SGM Nagar Sector-48, Faridabad" />}></Route>
          <Route path='/bike/delhi/kapashera' element={<AreaLocationPage location="Kapashera, Delhi" />}></Route>
          <Route path='/bike/ghaziabad/new-vijay-nagar' element={<AreaLocationPage location="New Vijay Nagar, Ghaziabad" />}></Route>
          <Route path='/bike/delhi/palam-vihar' element={<AreaLocationPage location="Palam Vihar, Delhi" />}></Route>
          <Route path='/bike/gurugram/sector-33' element={<AreaLocationPage location="Sector-33, Gurugram" />}></Route>
          <Route path='/bike/gurugram/sector-30' element={<AreaLocationPage location="Sector-30, Gurugram" />}></Route>
          <Route path='/bike/gurugram/sector-105' element={<AreaLocationPage location="Sector-105, Gurugram" />}></Route>
          <Route path='/bike/faridabad/ballabhgarh' element={<AreaLocationPage location="Ballabhgarh, Faridabad" />}></Route>
          <Route path='/bike/ghaziabad/kaushambi' element={<AreaLocationPage location="Kaushambi, Ghaziabad" />}></Route>
          <Route path='/bike/south-delhi/lado-sarai' element={<AreaLocationPage location="Lado Sarai, South Delhi" />}></Route>
          <Route path='/bike/kanpur' element={<AreaLocationPage location="Kanpur" />}></Route>
          <Route path='/bike/kolkata' element={<AreaLocationPage location="Kolkata" />}></Route>
          <Route path='/bike/Goa' element={<AreaLocationPage location="Goa" />}></Route>
          <Route path='/bike/chandigarh/setor-38' element={<AreaLocationPage location="Setor-38, Chandigarh" />}></Route>
          <Route path='/bike/greater-noida-west/supertech-ecovillage-1-Bisrakh' element={<AreaLocationPage location="Supertech Ecovillage -1, Bisrakh, Greater Noida West" />}></Route>
          <Route path='/bike/north-delhi/sant-nagar-burari' element={<AreaLocationPage location="Sant Nagar Burari, North Delhi" />}></Route>
          <Route path='/bike/east-delhi' element={<AreaLocationPage location="East Delhi" />}></Route>
          <Route path='/bike/west-delhi' element={<AreaLocationPage location="West Delhi" />}></Route>
          <Route path='/bike/north-delhi' element={<AreaLocationPage location="North Delhi" />}></Route>
          <Route path='/bike/south-delhi' element={<AreaLocationPage location="South Delhi" />}></Route>
*/}

          {/* <Route path='' element={<AreaLocationPage location="" />}></Route> */}
{/* -------- home ---------- */}


          <Route path='/garage-on-call' element={<Home />}></Route>
          <Route path='/doorstep-bike-servicing-and-repairing' element={<Home />}></Route>
          <Route path='/bike-service-at-home' element={<Home />}></Route>
          <Route path='/bike-service-near-me' element={<Home />}></Route>
          <Route path='/bike-repair-at-home' element={<Home />}></Route>
          <Route path='/bike-mechanic-near-me' element={<Home />}></Route>
          <Route path='/scooty-service-at-home' element={<Home />}></Route>
          <Route path='/bike-service' element={<Home />}></Route>
          <Route path='/bike-servicing-at-home' element={<Home />}></Route>
          <Route path='/two-wheeler-service-at-home' element={<Home />}></Route>
          <Route path='/online-bike-service' element={<Home />}></Route>
          <Route path='/scooter-repair-at-home' element={<Home />}></Route>
          <Route path='/scooty-service-near-me' element={<Home />}></Route>
          <Route path='/bike-service-online' element={<Home />}></Route>
          <Route path='/bike-repair-on-call' element={<Home />}></Route>
          <Route path='/bike-servicing' element={<Home />}></Route>
          <Route path='/doorstep-bike-service' element={<Home />}></Route>
          <Route path='/home-bike-service' element={<Home />}></Route>
          <Route path='/bike-home-service' element={<Home />}></Route>
          <Route path='/bike-repair-online' element={<Home />}></Route>
          <Route path='/bike-repair' element={<Home />}></Route>
          <Route path='/bike-mechanic-online' element={<Home />}></Route>
          <Route path='/scooter-service-at-home' element={<Home />}></Route>
          <Route path='/bike-mechanic' element={<Home />}></Route>
          <Route path='/bike-repair-home-service-near-me' element={<Home />}></Route>
          <Route path='/2-wheeler-service-at-home' element={<Home />}></Route>
          <Route path='/scooty-repair-at-home' element={<Home />}></Route>
          <Route path='/bike-repair-shops-near-me' element={<Home />}></Route>
          <Route path='/scooty-repair-online' element={<Home />}></Route>
          <Route path='/doorstep-bike-repair' element={<Home />}></Route>
          <Route path='/bike-service-website' element={<Home />}></Route>
          <Route path='/bike-repair-near-me' element={<Home />}></Route>
          <Route path='/online-bike-mechanic-near-me' element={<Home />}></Route>
          <Route path='/bike-service-home' element={<Home />}></Route>
          <Route path='/online-bike-repair' element={<Home />}></Route>
          <Route path='/online-bike-mechanic' element={<Home />}></Route>
          <Route path='/doorstep-bike-service-near-me' element={<Home />}></Route>
          <Route path='/scooty-repair-near-me' element={<Home />}></Route>
          <Route path='/bike-service-at-doorstep' element={<Home />}></Route>
          <Route path='/bike-garage-near-me' element={<Home />}></Route>
          <Route path='/scooty-service' element={<Home />}></Route>
          <Route path='/bike-service-online-booking' element={<Home />}></Route>
          <Route path='/bike-servicing-near-me' element={<Home />}></Route>
          <Route path='/bike-mechanic-at-home' element={<Home />}></Route>
          <Route path='/two-wheeler-mechanic-near-me' element={<Home />}></Route>
          <Route path='/scooty-service-at-home-near-me' element={<Home />}></Route>
          <Route path='/bike-doorstep-service' element={<Home />}></Route>
          <Route path='/online-bike-service-near-me' element={<Home />}></Route>
          <Route path='/scooty-mechanic-near-me' element={<Home />}></Route>
          <Route path='/bike-repair-service-at-home' element={<Home />}></Route>
          <Route path='/online-scooty-service' element={<Home />}></Route>
          <Route path='/bike-mechanic-on-call' element={<Home />}></Route>
          <Route path='/bike-service-at-home-near-me' element={<Home />}></Route>
          <Route path='/bike-repairing' element={<Home />}></Route>
          <Route path='/2-wheeler-home-service' element={<Home />}></Route>
          <Route path='/two-wheeler-service' element={<Home />}></Route>
          <Route path='/near-me-bike-mechanic' element={<Home />}></Route>
          <Route path='/bike-mechanic-on-call-near-me' element={<Home />}></Route>
          <Route path='/motorcycle-service-at-home' element={<Home />}></Route>
          <Route path='/bike-service-doorstep' element={<Home />}></Route>
          <Route path='/bike-repair-at-home-near-me' element={<Home />}></Route>
          <Route path='/bike-repair-at-doorstep' element={<Home />}></Route>
          <Route path='/scooty-home-service' element={<Home />}></Route>
          <Route path='/doorstep-mechanic' element={<Home />}></Route>
          <Route path='/two-wheeler-home-service' element={<Home />}></Route>
          <Route path='/scooty-service-online' element={<Home />}></Route>
          <Route path='/bike-repair-service' element={<Home />}></Route>
          <Route path='/bike-service-home-pickup' element={<Home />}></Route>
          <Route path='/bike-home-repair' element={<Home />}></Route>
          <Route path='/garage-call' element={<Home />}></Route>
          <Route path='/online-mechanic-for-bike' element={<Home />}></Route>
          <Route path='/scooter-repair-home-service-near-me' element={<Home />}></Route>
          <Route path='/bike-repair-shop-near-me' element={<Home />}></Route>
          <Route path='/bike-garage' element={<Home />}></Route>
          <Route path='/bike-service-price' element={<Home />}></Route>
          <Route path='/two-wheeler-doorstep-service' element={<Home />}></Route>
          <Route path='/online-bike-repair-near-me' element={<Home />}></Route>
          <Route path='/2-wheeler-service' element={<Home />}></Route>
          <Route path='/2-wheeler-mechanic-near-me' element={<Home />}></Route>
          <Route path='/bike-mechanic-near-my-location' element={<Home />}></Route>
          <Route path='/two-wheeler-services' element={<Home />}></Route>
          <Route path='/scooter-repair-home-service' element={<Home />}></Route>
          <Route path='/two-wheeler-service-near-me' element={<Home />}></Route>
          <Route path='/bike-repair-doorstep' element={<Home />}></Route>
          <Route path='/online-bike-servicing' element={<Home />}></Route>
          <Route path='/onsite-bike-repair' element={<Home />}></Route>
          <Route path='/online-bike-service-booking' element={<Home />}></Route>
          <Route path='/bike-repairing-online' element={<Home />}></Route>
          <Route path='/two-wheeler-garage-near-me' element={<Home />}></Route>
          <Route path='/local-bike-service' element={<Home />}></Route>
          <Route path='/bike-service-booking-online' element={<Home />}></Route>
          <Route path='/bike-repair-home' element={<Home />}></Route>
          <Route path='/bike-repair-services' element={<Home />}></Route>
          <Route path='/two-wheeler-service-center-near-me' element={<Home />}></Route>
          <Route path='/online-2-wheeler-service' element={<Home />}></Route>
          <Route path='/mechanic-on-call' element={<Home />}></Route>
          <Route path='/online-scooty-repair' element={<Home />}></Route>
          <Route path='/bike-servicing-online' element={<Home />}></Route>
          <Route path='/garage-near-me-for-bike' element={<Home />}></Route>
          <Route path='/online-bike-repair-service' element={<Home />}></Route>
          <Route path='/book-bike-service-online' element={<Home />}></Route>
          <Route path='/scooter-mechanic-online' element={<Home />}></Route>
          <Route path='/scooty-servicing-at-home' element={<Home />}></Route>
          <Route path='/home-bike-repair' element={<Home />}></Route>
          <Route path='/garage-near-me-for-two-wheeler' element={<Home />}></Route>
          <Route path='/bike-repair-online-service' element={<Home />}></Route>
          <Route path='/bike-online-service' element={<Home />}></Route>
          <Route path='/scooty-mechanic' element={<Home />}></Route>
          <Route path='/doorstep-bike-servicing' element={<Home />}></Route>
          <Route path='/scooty-servicing-near-me' element={<Home />}></Route>
          <Route path='/doorstep-bike-repair-near-me' element={<Home />}></Route>
          <Route path='/bike-servicing-price' element={<Home />}></Route>
          <Route path='/doorstep-2-wheeler-service' element={<Home />}></Route>
          <Route path='/two-wheeler-service-online' element={<Home />}></Route>
          <Route path='/scooty-repair-shops-near-me' element={<Home />}></Route>
          <Route path='/online-scooter-service' element={<Home />}></Route>
          <Route path='/home-scooter-service' element={<Home />}></Route>
          <Route path='/door-step-bike-service' element={<Home />}></Route>
          <Route path='/nearby-bike-garage' element={<Home />}></Route>
          <Route path='/home-bike-service-near-me' element={<Home />}></Route>
          <Route path='/service-at-your-doorstep' element={<Home />}></Route>
          <Route path='/nearby-bike-mechanic' element={<Home />}></Route>
          <Route path='/bike-service-in-home' element={<Home />}></Route>
          <Route path='/mechanic-for-bike-near-me' element={<Home />}></Route>
          <Route path='/scooter-repair-online' element={<Home />}></Route>
          <Route path='/bike-service-at-door-step' element={<Home />}></Route>
          <Route path='/2-wheeler-repair-at-home' element={<Home />}></Route>
          <Route path='/best-bike-mechanic-near-me' element={<Home />}></Route>
          <Route path='/bike-home-service-near-me' element={<Home />}></Route>
          <Route path='/doorstep-two-wheeler-service-near-me' element={<Home />}></Route>
          <Route path='/two-wheeler-online-service' element={<Home />}></Route>
          <Route path='/motorcycle-service' element={<Home />}></Route>
          <Route path='/bike-mechanic-service' element={<Home />}></Route>
          <Route path='/bike-mechanic-at-home-service' element={<Home />}></Route>
          <Route path='/bike-repairing-service' element={<Home />}></Route>
          <Route path='/2-wheeler-servicing-at-home' element={<Home />}></Route>
          <Route path='/two-wheeler-service-at-doorstep' element={<Home />}></Route>
          <Route path='/bike-service-centre-near-me' element={<Home />}></Route>
          <Route path='/mechanic-online-booking' element={<Home />}></Route>
          <Route path='/bike-repair-pickup' element={<Home />}></Route>
          <Route path='/two-wheeler-workshop-near-me' element={<Home />}></Route>
          <Route path='/home-bike-mechanic' element={<Home />}></Route>
          <Route path='/bike-service-near-me' element={<Home />}></Route>
          <Route path='/bike-mechanic-near-by-me' element={<Home />}></Route>
          <Route path='/mechanic-bike-near-me' element={<Home />}></Route>
          <Route path='/two-wheeler-servicing-charges' element={<Home />}></Route>
          <Route path='/2-wheeler-mechanic' element={<Home />}></Route>
          <Route path='/scooty-repairing-near-me' element={<Home />}></Route>
          <Route path='/online-booking-for-bike-service' element={<Home />}></Route>
          <Route path='/bike-general-service-cost' element={<Home />}></Route>
          <Route path='/motorcycle-repair-shops-near-me' element={<Home />}></Route>
          <Route path='/scooty-service-centre-near-me' element={<Home />}></Route>
          <Route path='/general-bike-service-cost' element={<Home />}></Route>
          <Route path='/bike-mechanic-near-me-now' element={<Home />}></Route>
          <Route path='/home-service-for-two-wheeler' element={<Home />}></Route>
          <Route path='/best-bike-service-at-home' element={<Home />}></Route>
          <Route path='/bike-mechanic-service-near-me' element={<Home />}></Route>
          <Route path='/home-bike-repair-service-near-me' element={<Home />}></Route>
          <Route path='/bike-repair-center' element={<Home />}></Route>
          <Route path='/motorbike-mechanic' element={<Home />}></Route>
          <Route path='/motorbike-service-near-me' element={<Home />}></Route>
          <Route path='/bike-battery-change-at-home' element={<Home />}></Route>
                   
          {/* <Route path='/' element={<Home />}></Route> */}
          
{/* ------- testimonial ------------- */}
<Route path='/garage-on-call-reviews' element={<TestimonialPage />}></Route>


          {/*--------------------- admin -------------------------*/}

          <Route path='/admin' element={<AdminLoginPage/>}></Route>
          <Route path='/admin/home' element={<AdminHomePage/>}></Route>
          <Route path='/admin/bookings' element={<AdminBookingPage/>}></Route>
          <Route path='/admin/inquiry' element={<AdminInquiryPage/>}></Route>
          <Route path='/admin/contact-us' element={<AdminContactUs/>}></Route>





          {/* <Route path='/others' element={<LocationBikeModelPage/>}></Route> */}
          {/* <Route path='/other' element={<OtherPage bike="Royal Enfield" />}></Route> */}
          {/* <Route path='/other' element={<OtherPage model="TVS Apache RTR 310" />}></Route> */}
          {/* <Route path='/other' element={<OtherPage location="New Delhi"/>}></Route> */}
          {/* <Route path='/other' element={<OtherPage/>}></Route> */}
          {/* <Route path='/other' element={<OtherNewPage />}></Route> */}
          <Route path='*' element={<ErrorPage />}></Route>
          <Route path='/error' element={<ErrorPage />}></Route>
          
          
          
        </Routes>
        <ScrollButton />

        {/* <ButtonComponent /> */}
      </BrowserRouter>

    </div>
  );
}

export default App;
