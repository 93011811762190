import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../../images/logo.png";

const Navbar = () => {

  

  const location = useLocation();
  const path = location.pathname;
  
  
  
  

    const [menu, setMenu] = useState("")
    
  return (
    <div>
      <div className="header-main">
        <div className="header-top">
          <div>
            <div className="header-top-col">
              <Link
               className="header-top-col-mail"
                title="Garage On Call - Doorstep Bike Service Booking Email Contact"
                target="_blank"
                to="mailto:query@garageoncall.com"
              >
                <i class="fa-solid fa-envelope"></i> query@garageoncall.com
              </Link>
              <Link onClick={e=>setMenu("")} title="Garage On Call - Book Bike Service at Home Call Contact" target="_blank" to="tel:+91 9658-124-124">
                <i class="fa-solid fa-phone"></i> +91 9658-124-124
              </Link>
            </div>

            <div className="header-top-col">
              <Link
                title="Garage On Call - Facebook Profile"
                target="_blank"
                to="https://www.facebook.com/garageoncallofficial/"
                className="header-top-col-icon"
              >
                <i class="fa-brands fa-facebook-f"></i>
              </Link>
              <Link
                title="Garage On Call - X / Twitter Profile"
                target="_blank"
                to="https://x.com/i/flow/login?redirect_after_login=%2FGarageoncall"
                className="header-top-col-icon"
              >
                <i class="fa-brands fa-x-twitter"></i>
              </Link>
              <Link
                title="Garage On Call - Youtube Profile"
                target="_blank"
                to="https://www.youtube.com/@GarageOnCall"
                className="header-top-col-icon"
              >
                <i class="fa-brands fa-youtube"></i>
              </Link>
              <Link
                title="Garage On Call - Linkedin Profile"
                target="_blank"
                to="https://www.linkedin.com/company/garage-on-call/"
                className="header-top-col-icon"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </Link>
              <Link
                title="Garage On Call - Instagram Profile"
                target="_blank"
                to="https://www.instagram.com/garageoncallofficial/"
                className="header-top-col-icon"
              >
                <i class="fa-brands fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="header-nav-content">

        <div className="header-nav-content-main">
        <Link onClick={e=>setMenu("")} to="/" className="header-nav-logo"><img src={logo} alt="GOC - Logo" title="Garage On Call - Bike service & repair at home" /></Link>
          <div className={`header-nav-menu ${menu}`}>
            <Link className={path==="/"?"header-nav-menu-red":""} onClick={e=>setMenu("")} title="Garage On Call - Book Doorstep Bike Service" to="/">
              HOME
            </Link>
            <Link className={path==="/book-bike-service"?"header-nav-menu-red":""} onClick={e=>setMenu("")} title="Garage On Call - Book Doorstep Bike Service" to="/book-bike-service">
              BOOK SERVICE
            </Link>


            <div className="header-nav-menu-drop">
              <span className={path==="/our-prices"?"header-nav-menu-red":path==="/bike/routine-service"?"header-nav-menu-red":path==="/bike/rsa-service"?"header-nav-menu-red":""} >
              PRICING <i className="fa-solid fa-caret-down"></i>
              </span>   
              <div className="header-nav-menu-drop-content">
                <div className="nav-d-c-link">
                  <Link className={path==="/our-prices"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Garage On Call - Doorstep bike service price" to="/our-prices">
                    Our Price
                  </Link>
                  <Link className={path==="/bike/routine-service"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Garage On Call - At-home bike service price" to="/bike/routine-service">
                    Routine Service
                  </Link>
                  <Link className={path==="/bike/rsa-service"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="TwoGarage On Call - Road Side Assistance Price" to="/bike/rsa-service">
                    {/* RSA Services */}
                    Road Side Assistance
                  </Link>
                  
                </div>
              </div>
            </div>

            
            <Link className={path==="/our-services"?"header-nav-menu-red":""} onClick={e=>setMenu("")} title="Two-wheeler services at home" to="/our-services">
              OUR SERVICES
            </Link>

            <div className="header-nav-menu-drop">
              <span  className={path==="/about-us"?"header-nav-menu-red":path==="/our-team"?"header-nav-menu-red":path==="/testimonials"?"header-nav-menu-red":path==="/our-locations"?"header-nav-menu-red":path==="/blog"?"header-nav-menu-red":""}  >
                COMPANY <i className="fa-solid fa-caret-down"></i>
              </span>   
              <div className="header-nav-menu-drop-content">
                <div className="nav-d-c-link">
                  <Link className={path==="/about-us"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="About - Garage On Call" to="/about-us">
                    About Us
                  </Link>
                  <Link className={path==="/our-team"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Garage On Call - Expert mechanics & team" to="/our-team">
                    Our Team
                  </Link>
                  <Link className={path==="/testimonials"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Garage On Call - Customers reviews" to="/testimonials">
                    Testimonial
                  </Link>
                  <Link className={path==="/our-locations"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Garage On Call - Bike service area" to="/our-locations">
                    Our Locations
                  </Link>
                </div>
              </div>
            </div>

            <Link className={path==="/contact-us"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Contact - Garage On Call" to="/contact-us">
              CONTACT
            </Link>



            <div className="header-nav-menu-drop">
              <span >
                Blog <i className="fa-solid fa-caret-down"></i>
              </span>   
              <div className="header-nav-menu-drop-content">
                <div className="nav-d-c-link">
                  <Link className={path==="/blog"?"header-nav-menu-red":""}  onClick={e=>setMenu("")} title="Garage On Call - Bike guide, tips & tricks articles & blog" to="/blog">
                    Our Blog
                  </Link>
                  
                  <Link onClick={e=>setMenu("")} title="Garage On Call - Bike Blog" to="/blog/category/bike">
                    Bike Blog
                  </Link>
                  <Link onClick={e=>setMenu("")} title="Garage On Call - Scooty Blog" to="/blog/category/scooty">
                    Scooty Blog
                  </Link>
                  <Link onClick={e=>setMenu("")} title="Garage On Call - Electric Vehicle Blog" to="/blog/category/ev">
                    EV Blog
                  </Link>
                </div>
              </div>
            </div>


            <span className="header-nav-menu-button header-nav-menu-button-close">
              <i onClick={e=>setMenu("")} className="fa-solid fa-circle-xmark"></i>
            </span>
          </div>

          <span className="header-nav-menu-button ">
            <i onClick={e=>setMenu("header-nav-menu-active")} className="fa-solid fa-bars"></i>
          </span>

        </div>
        </div>

      </div>
    </div>
  );
};

export default Navbar;
