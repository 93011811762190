import React from "react";
import OurPriceComponent from "./OurPriceComponent";
import { Link } from "react-router-dom";

const PriceRoutinePageComponent = ({type}) => {
  return (
    <div>
      <div className="package-price">
        <div className="package-price-content">
          <div className="package-price-content-col-1">
            <div>
              <div className="package-price-content-col-1-heading">
                Save your <span>Time</span> and <span>Money</span>
              </div>
              <div className="package-price-content-col-1-text">
                Choose plan that fit to your bike, feel free to contact us if
                you need more details.
              </div>
              <div className="package-price-content-col-1-contact">
                
                <Link target="_blank" to="tel:+91 9658-124-124" title="Online bike service phone number" className="package-price-content-col-1-contact-col">
                  <i class="fa-solid fa-phone-volume shake"></i>
                  <div className="flipX">0120 4225828</div>
                </Link>
                
                <Link target="_blank" to="https://wa.me/+919658124124?text=Hello%20!%20I%27m%20Interested%20in%20Doorstep%20Bike%20Service.%20Please%20contact%20me." title="Book online bike service & repair" className="package-price-content-col-1-contact-col">
                  <i class="fa-brands fa-whatsapp shake"></i>
                  <div className="flipX">+91 9658-124-124</div>
                </Link>
                
              </div>


              <div className="package-price-content-col-1-content"></div>
            </div>
          </div>

          <div className="package-price-content-col-2">
            <div className="package-price-heading">
              {
                type === "At-Home"?<>Routine Service <span>Price list</span></>
                :type === "Road Side"?<>RSA Service <span>Price list</span></>
                :""
              }
              
            </div>
            <div className="package-price-head-text">
              Wondering how much does it cost to service a bike? Bike Repair or
              Motorcycle service cost may depend upon the type of service you
              opt for. Refer below to get the estimated labour of Bike Service.
            </div>

            <OurPriceComponent type={type} />

          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRoutinePageComponent;
