import React, { useState } from "react";
import PopupNumberForm from "../forms/PopupNumberForm";

const PackagePriceComponent = () => {
  
  const [popup, setPopup] = useState("")
  const [numForm, setNumForm] = useState(false)
  const [type, setType] = useState("At-Home")
  const [heading, setHeading] = useState()
  
  return (
    <div>

{
  numForm?
  <PopupNumberForm open={setNumForm} heading={heading}/>
  :""
}
      
      {
        popup==="regular"?
      
      <div className="package-price-popup">
      <div className="package-price-popup-main">

        <div className="package-price-popup-head">
          <div className="package-price-popup-head-col">
            <div className="package-price-popup-head-heading-1">{type} Regular Service</div>
            <div className="package-price-popup-head-heading-2">Below 125 CC</div>
          </div>
          <div className="package-price-popup-head-col"><i onClick={e=>setPopup(false)} className="fa-solid fa-circle-xmark"></i></div>
        </div>

        <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
          <ul>

<li>Engine Oil Change (Price Extra)</li>
<li>Oil Filter Clean (if Replace Charges)</li>
<li>Air Filter Clean (if Replace Charges)</li>
<li>Spark Plug Clean (if Replace Charges)</li>
<li>Tightening of Screws, Bolts & Nuts</li>
<li>Average and Performance Check-up</li>
<li>Brakes – Front & Rear Adjust</li>
<li>Driven Chain Basic Cleaning</li>
<li>Carburettor Basic Check-up</li>
<li>Minor Electrical Check-up</li>
<li>Battery General Check-up</li>
<li>Basic Engine Inspection</li>
<li>Basic Fork Inspection</li>
<li>Basic Hand Cleaning</li>
<li>Free Pick and Drop</li>

            
          </ul>
        </div>
        <div onClick={e=>(setNumForm(true) || setHeading(`${type} Regular Service`))} className="package-price-popup-content-button">Book Now</div>

        </div>


      </div>
      </div>

        :popup==="classic"?

        <div className="package-price-popup">
      <div className="package-price-popup-main">

        <div className="package-price-popup-head">
          <div className="package-price-popup-head-col">
            <div className="package-price-popup-head-heading-1">{type} Classic Service</div>
            <div className="package-price-popup-head-heading-2">125 CC to 199 CC</div>
          </div>
          <div className="package-price-popup-head-col"><i onClick={e=>setPopup(false)} className="fa-solid fa-circle-xmark"></i></div>
        </div>

        <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
          <ul>

<li>Engine Oil Change (Price Extra)</li>
<li>Oil Filter Clean (if Replace Charges)</li>
<li>Air Filter Clean (if Replace Charges)</li>
<li>Spark Plug Clean (if Replace Charges)</li>
<li>Tightening of Screws, Bolts & Nuts</li>
<li>Average and Performance Check-up</li>
<li>Brakes – Front & Rear Adjust</li>
<li>Driven Chain Basic Cleaning</li>
<li>Carburettor Basic Check-up</li>
<li>Minor Electrical Check-up</li>
<li>Battery General Check-up</li>
<li>Basic Engine Inspection</li>
<li>Basic Fork Inspection</li>
<li>Basic Hand Cleaning</li>
<li>Oiling and Greasing</li>
<li>Coolant Check-up</li>
<li>Free Pick and Drop</li>

            
          </ul>
        </div>
        <div onClick={e=>(setNumForm(true) || setHeading( `${type} Classic Service`))} className="package-price-popup-content-button">Book Now</div>

        </div>


      </div>
      </div>
        

        :popup==="premium"?
       
        
        <div className="package-price-popup">
      <div className="package-price-popup-main">

        <div className="package-price-popup-head">
          <div className="package-price-popup-head-col">
            <div className="package-price-popup-head-heading-1">{type} Premium  Service</div>
            <div className="package-price-popup-head-heading-2">200 CC to 299 CC</div>
          </div>
          <div className="package-price-popup-head-col"><i onClick={e=>setPopup(false)} className="fa-solid fa-circle-xmark"></i></div>
        </div>

        <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
          <ul>

<li>Engine Oil Change (Price Extra)</li>
<li>Oil Filter Clean (if Replace Charges)</li>
<li>Air Filter Clean (if Replace Charges)</li>
<li>Spark Plug Clean (if Replace Charges)</li>
<li>Tightening of Screws, Bolts & Nuts</li>
<li>Average and Performance Check-up</li>
<li>Brakes – Front & Rear Adjust</li>
<li>Driven Chain Basic Cleaning</li>
<li>Carburettor Basic Check-up</li>
<li>Minor Electrical Check-up</li>
<li>Battery General Check-up</li>
<li>Basic Engine Inspection</li>
<li>Fuel Pump Motor Check-up</li>
<li>Basic Fork Inspection</li>
<li>Basic Hand Cleaning</li>
<li>Oiling and Greasing</li>
<li>Injector Check-up</li>
<li>Coolant Check-up</li>
<li>Free Pick and Drop</li>

            
          </ul>
        </div>
        <div onClick={e=>(setNumForm(true) || setHeading(`${type} Premium  Service`))} className="package-price-popup-content-button">Book Now</div>

        </div>


      </div>
      </div>
        
        

        :popup==="royal"?
        
        
        <div className="package-price-popup">
      <div className="package-price-popup-main">

        <div className="package-price-popup-head">
          <div className="package-price-popup-head-col">
            <div className="package-price-popup-head-heading-1">{type} Royal Service</div>
            <div className="package-price-popup-head-heading-2">300 CC to 349 CC</div>
          </div>
          <div className="package-price-popup-head-col"><i onClick={e=>setPopup(false)} className="fa-solid fa-circle-xmark"></i></div>
        </div>

        <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
          <ul>

<li>Engine Oil Change (Price Extra)</li>
<li>Oil Filter Clean (if Replace Charges)</li>
<li>Air Filter Clean (if Replace Charges)</li>
<li>Spark Plug Clean (if Replace Charges)</li>
<li>Tightening of Screws, Bolts & Nuts</li>
<li>Average and Performance Check-up</li>
<li>Brakes – Front & Rear Adjust</li>
<li>Driven Chain Basic Cleaning</li>
<li>Carburettor Basic Check-up</li>
<li>Minor Electrical Check-up</li>
<li>Battery General Check-up</li>
<li>Basic Engine Inspection</li>
<li>Fuel Pump Motor Check-up</li>
<li>Basic Fork Inspection</li>
<li>Basic Hand Cleaning</li>
<li>Oiling and Greasing</li>
<li>Injector Check-up</li>
<li>Coolant Check-up</li>
<li>Free Pick and Drop</li>

            
          </ul>
        </div>
        <div onClick={e=>(setNumForm(true) || setHeading(`${type} Royal Service`))} className="package-price-popup-content-button">Book Now</div>

        </div>


      </div>
      </div>
        
        

        :popup==="sports"?
        
        
        
        <div className="package-price-popup">
      <div className="package-price-popup-main">

        <div className="package-price-popup-head">
          <div className="package-price-popup-head-col">
            <div className="package-price-popup-head-heading-1">{type} Sports Service</div>
            <div className="package-price-popup-head-heading-2">Above 350 CC</div>
          </div>
          <div className="package-price-popup-head-col"><i onClick={e=>setPopup(false)} className="fa-solid fa-circle-xmark"></i></div>
        </div>

        <div className="package-price-popup-content">
        <div className="package-price-popup-content-points">
          <ul>

<li>Engine Oil Change (Price Extra)</li>
<li>Oil Filter Clean (if Replace Charges)</li>
<li>Air Filter Clean (if Replace Charges)</li>
<li>Spark Plug Clean (if Replace Charges)</li>
<li>Tightening of Screws, Bolts & Nuts</li>
<li>Average and Performance Check-up</li>
<li>Brakes – Front & Rear Adjust</li>
<li>Driven Chain Basic Cleaning</li>
<li>Carburettor Basic Check-up</li>
<li>Minor Electrical Check-up</li>
<li>Battery General Check-up</li>
<li>Basic Engine Inspection</li>
<li>Fuel Pump Motor Check-up</li>
<li>Basic Fork Inspection</li>
<li>Basic Hand Cleaning</li>
<li>Fuel Pump Motor Check-up</li>
<li>Oiling and Greasing</li>
<li>Injector Check-up</li>
<li>Coolant Check-up</li>
<li>Free Pick and Drop</li>

{/* <li>Fuel Pump Motor Check-up</li>
<li>Oiling and Greasing</li>
<li>Injector Check-up</li>
<li>Coolant Check-up</li> */}
            
          </ul>
        </div>
        <div onClick={e=>(setNumForm(true) || setHeading(`${type} Sports Service`))} className="package-price-popup-content-button">Book Now</div>

        </div>


      </div>
      </div>
        
        
        

        :popup==="reg"?
        ""
        
      :""
}

{/* ----------------------------------------------------------------------- */}
      <div className="package-price">
        <div className="package-price-content">
          <div className="package-price-content-col-1">
            <div>
              <div className="package-price-content-col-1-heading">
                Save your <span>Time</span> and <span>Money</span>
              </div>
              <div className="package-price-content-col-1-content">
                <div onClick={e=>setType("At-Home")} className={type==="At-Home"? "package-price-content-col-1-col-red":"package-price-content-col-1-col"}>
                  Service Charges
                </div>
                <div onClick={e=>setType("Road Side")} className={type==="Road Side"? "package-price-content-col-1-col-red":"package-price-content-col-1-col"}>
                  RSA Charges
                </div>
                {/* <div className="package-price-content-col-1-col">
                  Labour Charges
                </div> */}
              </div>
            </div>
          </div>

          <div className="package-price-content-col-2">
            <div className="package-price-heading">
              Bike Service <span>Price list</span>
            </div>
            <div className="package-price-head-text">
              Wondering how much does it cost to service a bike? Bike Repair or
              Motorcycle service cost may depend upon the type of service you
              opt for. Refer below to get the estimated labour of Bike Service.
              {/* Please note, actual costs may vary, kindly get the final quotation from the assigned bike Mechanic before availing the service. */}
            </div>
            <div className="package-price-head-category">
              <div onClick={e=>setType("At-Home")} className={type==="At-Home"? "package-price-head-category-col-red":"package-price-head-category-col"}>Service</div>
              <div onClick={e=>setType("Road Side")} className={type==="Road Side"? "package-price-head-category-col-red":"package-price-head-category-col"}>RSA</div>
              {/* <div className="package-price-head-category-col">Labour</div> */}

              <span>
                {
                  type==="At-Home"?
                  <i className="fa-solid fa-caret-down"></i>
                  :""
                }
              </span>

              <span>
                {
                  type==="Road Side"?
                  <i className="fa-solid fa-caret-down"></i>
                  :""
                }
              </span>
              
            </div>

            <div className="package-price-content-col-2-content">
              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                  {type} Regular Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  Below 125 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 599 </s>&nbsp; Rs. 299 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                    
                  </div>
                  <div onClick={e=>setPopup("regular")} className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Classic Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  125 CC to 199 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 799 </s>&nbsp; Rs. 399 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div  onClick={e=>setPopup("classic")}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Premium Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  200 CC to 299 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 1,199 </s>&nbsp; Rs. 499 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div onClick={e=>setPopup("premium")}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Royal Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  300 CC to 349 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 1,599 </s>&nbsp; Rs. 599 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div onClick={e=>setPopup("royal")}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Sports Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  Above 350 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 2,199 </s>&nbsp; Rs. 999 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div onClick={e=>setPopup("sports")}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePriceComponent;
