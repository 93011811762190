import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon1 from '../../images/icon/Periodic-Service.png'
import icon2 from '../../images/icon/Dent-Paint.png'
import icon3 from '../../images/icon/Bike-Insurance.png'
import icon4 from '../../images/icon/Doorstep-Bike-Services.png'
import icon5 from '../../images/icon/Bike-Wheel-Care.png'
import icon6 from '../../images/icon/Bike-Washing-Detailing.png'
import icon7 from '../../images/icon/Accident-Repair.png'
import icon8 from '../../images/icon/Bike-Battery.png'
import icon9 from '../../images/icon/Light-Fitments.png'
import icon10 from '../../images/icon/EV-Bike-services.png'
import icon11 from '../../images/icon/Bike-Engine-Repair.png'
import icon12 from '../../images/icon/Bike-RSA-Service.png'
import PopupNumberForm from "../forms/PopupNumberForm";

const CategoryComponent = ({bike, model, location}) => {

  const [form, setForm] = useState(false)
  const [heading, setHeading] = useState()
  const [heading2, setHeading2] = useState()
  
  return (
      <div className="">

        {form?
        <PopupNumberForm open={setForm} heading={heading} heading2={heading2}/>
        :""}
      <div className="category-component">
          <div className="category-component-heading">
            {
            bike? <><span>{bike} </span>Bike Services <>at Home</></> 
            :model? <><span>{model} </span>Services <>at Home</></> 
            :location? <>Services at Home in <span>{location} </span></> 
            :
            <>Bike Services <span>at Home</span></>
            }

          </div>
          <div className="category-component-head-text">
          Get professional periodic bike service at Home. Get bike engine repair, doorstep battery replacement, wheel, and tyre care services. Also, you can buy bike insurance and much more done at the best prices.
          </div>
      {/* <div className="heading-underline"><hr /></div> */}

      <div className="category-component-content">

        <Link title="Doorstep two wheeler routine Service" to="/our-prices" className="category-component-col">
          <div className="category-component-col-img"><img src={icon1} alt="icon" title="Bike routine Service at home" /></div>
          <div className="category-component-col-text">Routine Service</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Dent & Paint Service") || setHeading2("Now Get Your Bike Dent & Paint Service at Home and Save Your Time and Money!"))} title="Doorstep two wheeler Dent & Paint service" className="category-component-col">
          <div className="category-component-col-img"><img src={icon2} alt="icon" title="Bike Dent & Paint Service at home" /></div>
          <div className="category-component-col-text">Dent & Paint</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Bike Insurance Service") || setHeading2("Now Get Your Bike Insurance Service at Home and Save Your Time and Money!"))} title="Online Two wheeler Insurance" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon3} alt="icon" title="Online Bike Insurance Service" /></div>
          <div className="category-component-col-text">Bike Insurance</div>
        </Link>


        <Link to="/bike/routine-service" className="category-component-col">
          <div className="category-component-col-img"><img src={icon4} alt="icon" title="Bike Service at home" /></div>
          <div className="category-component-col-text">Doorstep Service</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Wheel Care Service") || setHeading2("Now Get Your Bike Wheel Care Service at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler Wheel Care Service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon5} alt="icon" title="Bike Wheel Care Service at home" /></div>
          <div className="category-component-col-text">Wheel Care</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Detailing Service") || setHeading2("Now Get Your Bike Detailing Service at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler detailing service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon6} alt="icon" title="Bike Detailing Service at home" /></div>
          <div className="category-component-col-text">Detailing</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Accidental Repair Service") || setHeading2("Now Get Your Accidental Bike Repair at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler Accidental Repair service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon7} alt="icon" title="Bike Accidental Repair Service at home" /></div>
          <div className="category-component-col-text">Accidental Repair</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Bike Batteries Service") || setHeading2("Now Get Your Bike Batteries Service at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler battery service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon8} alt="icon" title="Bike Batteries Service at home" /></div>
          <div className="category-component-col-text">Bike Batteries</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Lights & Fitments Service") || setHeading2("Now Get Your Bike Lights & Fitments Service at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler Lights & Fitments service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon9} alt="icon" title="Bike Lights & Fitments Service at home" /></div>
          <div className="category-component-col-text">Lights & Fitments</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Wiring Service Service") || setHeading2("Now Get Your Bike Wiring Service at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler Wiring service" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon10} alt="icon" title="Bike Wiring Service at home" /></div>
          <div className="category-component-col-text">Wiring Service</div>
        </Link>


        <Link onClick={e=>(setForm(true) || setHeading("Engine Repair Service") || setHeading2("Now Get Your Bike Engine Repair at Home and Save Your Time and Money!"))}  title="Doorstep two wheeler Engine Repair" to="" className="category-component-col">
          <div className="category-component-col-img"><img src={icon11} alt="icon" title="Bike Engine Repair at home" /></div>
          <div className="category-component-col-text">Engine Repair</div>
        </Link>


        <Link title="Two wheeler Road Side Assistance service" to="/bike/rsa-service" className="category-component-col">
          <div className="category-component-col-img"><img src={icon12} alt="icon" title="Bike Road Side Assistance Service" /></div>
          <div className="category-component-col-text">Bike RSA Services</div>
        </Link>

      </div>
    </div>
    </div>
  );
};

export default CategoryComponent;
