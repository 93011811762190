import React, { useState } from "react";
import CountUp from "react-countup";

import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";

export const Header = () => {

  const [loading, setLoading] = useState(false);


  // post ----------------------------------------------------------

  const navigate = useNavigate()


  const [user, setUser] = useState({
    phone: "",
    path: "Home page header",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.shop.garageoncall.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // setBookingForm(false);
        alert("Service booked successfully");
        navigate("/thanks");

      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);

  };











  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------



  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className='header'>

        <div className='header-col-1'>
          <h1 className='header-c-heading-1'>Bike Service & Repair <br />at Your Doorstep</h1>
          <h2 className='header-c-heading-2'>Book Expert Mechanic to Fix <br />your Bike at Home</h2>

          <form className='header-c-form' onSubmit={handleSubmit}>
            <input type='number' placeholder='Enter your number'
              required
              name="phone"
              onChange={handleChange}
            />
            <br />
            <button type="submit"><div className="">BOOK NOW</div></button>
          </form>

          <div className='header-c-inc'>
            <div className='header-c-inc-col'>
            <h3>
              <CountUp start={0}  end={4} duration="2"  enableScrollSpy />.<CountUp start={0} end={7} duration="7"  enableScrollSpy />/ 5
            </h3>

              {/* <span id='counter4'></span><span className=''>.</span><span id='counter7'></span>/ 5 */}
              <h4 className=''>Based on 1,00,000+ <br /> Reviews</h4>

            </div>
            <div className='header-c-inc-col'>
            <h3>
              <CountUp start={0} end={85000} duration="3"  enableScrollSpy /> +
            </h3>
              {/* <span id='counter50000'></span> + */}
              <h4 className=''>Happy <br /> Customers</h4>

            </div>


          </div>

        </div>



        <div className='header-col-2'>
          {/* <img className="head-img-1" src={img1} />
          <img className="head-img-2 imgrot" src={img2} /> */}
          {/* <img className="head-img-3" src={img} /> */}
          {/* <img className="head-img-4" src={img3} /> */}
        </div>



      </div>


    </div>
  )
}
