import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogFixComponent from "./BlogFixComponent";
import blogData from "../blogs/DataBlog.json"
import { Helmet } from "react-helmet";


const  FilterBlogPageComponent = ({type}) => {

  
  const [currentPage, setCurrentPage] = useState(1)
  // const recordPerPage = 15;
  const recordPerPage = type === "bike"?"15":type ==="scooty"?"5":"5";
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;

  const data = blogData.filter(e=> e.category === type )
  
  const records = data.slice(firstIndex, lastIndex);

  
  const npage = Math.ceil(data.length / recordPerPage)
  const numbers = [...Array(npage + 1).keys()].slice(1)


  const prevPage = e => {
      if (currentPage !== 1) {
          setCurrentPage(currentPage - 1)
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      }
  }
  const changeCPage = e => {
      setCurrentPage(e)
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  }
  const nextPage = e => {
      if (currentPage !== npage) {
          setCurrentPage(currentPage + 1)
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      }
  }

  
  
  return (
    <div>

      
<Helmet>
        <title>Book Bike Mechanic Online to service your bike at home</title>
        <meta name="description" content={`
        We provide online platform to book a ${type} mechanic for any kind of bike related problems ${type} mechanic reach at your home to repair and service your ${type}.
        `} />
      </Helmet>

      
      <div className="our-blog-page">
      <h1 className="our-blog-page-heading"><span> {type==="bike"?"Bike":type==="scooty"?"Scooty":type==="Electric Vehicle"?"Electric Vehicle": "Our Latest"} <span>Blog</span> and <span>Articles</span></span></h1>
        
        <div className="our-blog-page-main">
          
          <div className="our-blog-page-col">

            {/* ------------------------------------------------------------ */}




{
                                records.map(e => (
  
            <div className="our-blog-page-blog">
              <div className="our-blog-page-blog-img">
                <img
                  src={`${e.img}`}
                  alt=""
                />
                {/* <div className="our-blog-page-blog-heading">{e.category}</div> */}
              </div>

              <div className="our-blog-page-blog-content">
                <h2 className="our-blog-page-blog-heading">{e.title}</h2>
                <div className="our-blog-page-blog-head">
                  <span> {e.date}</span>
                  <span>ADMIN</span>
                </div>
                <div className="our-blog-page-blog-text">{e.text}</div>
                <div className="our-blog-page-blog-button">
                  <Link to={e.link}>Read more...</Link>
                </div>
              </div>
            </div>

))
}
            




            {/* ------------------------------------------------------------ */}
            
          


{/* ------------------------------------------------------------------------- */}


<div className="blog-component-col-2-pagination">
                            <div className="blog-component-col-2-pagination-buttons ">
                                <div className={`blog-component-col-2-pagination-btn ${currentPage === 1 ? 'blog-component-col-2-pagination-btn-disable' : ''}`} onClick={prevPage}><i className="fa-solid fa-angle-left"></i></div>
                                {
                                    numbers.map((n, i) => (
                                        <div className={`blog-component-col-2-pagination-btn ${currentPage === n ? 'blog-component-col-2-pagination-btn-active' : ''}`} key={i} onClick={() => changeCPage(n)}>{n}</div>
                                    ))
                                }


                                <div className={`blog-component-col-2-pagination-btn ${currentPage === numbers.length ? 'blog-component-col-2-pagination-btn-disable' : ''}`} onClick={nextPage}><i className="fa-solid fa-angle-right"></i></div>

                            </div>



                        </div>


{/* ------------------------------------------------------------------------- */}

</div>
<BlogFixComponent/>

          
        </div>
      </div>
    </div>
  );
};

export default FilterBlogPageComponent;
