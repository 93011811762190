import React from "react";
import google from "../../images/Google.png"
import facebook from "../../images/FaceBook.png"
import justdial from "../../images/JustDial.png"
import { Link } from "react-router-dom";

const ClientSaysComponent = () => {
  return (
    <div>
      <div className="client-say">
      <div className="client-say-heading">What Our <span>Clients</span> Say<span>?</span></div>
      {/* <div className="heading-underline"><hr /></div> */}

      <div className="client-say-content">

        <div className="client-say-col">
          <div className="client-say-col-img"><img src={google} alt="social media" title="Garage On Call - Google Rating" /></div>
          <div className="client-say-col-rating">
            <span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </span>
            &nbsp; 4.7/5 Rating
          </div>
          <div className="client-say-col-link"><Link title="Garage On Call - Google Profile" target="_blank" to='https://g.co/kgs/NH33KRD'>view us on Google</Link></div>
        </div>
        

        <div className="client-say-col">
          <div className="client-say-col-img"><img src={facebook} alt="social media" title="Garage On Call - Facebook Rating" /></div>
          <div className="client-say-col-rating">
            <span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              {/* <i class="fa-solid fa-star-half-stroke"></i> */}
            </span>
            &nbsp; 4.8/5 Rating
          </div>
          <div className="client-say-col-link"><Link title="Garage On Call - Facebook Profile" target="_blank" to='https://www.facebook.com/garageoncallofficial/'>view us on FaceBook</Link></div>
        </div>

        <div className="client-say-col">
          <div className="client-say-col-img"><img src={justdial} alt="social media" title="Garage On Call - Justdial Rating" /></div>
          <div className="client-say-col-rating">
            <span>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </span>
            &nbsp; 4.7/5 Rating
          </div>
          <div className="client-say-col-link"><Link title="Garage On Call - Justdial Profile" target="_blank" to='https://jsdl.in/DT-231WDUMJD1P'>view us on JustDial</Link></div>
        </div>

      </div>
      </div>
    </div>
  );
};

export default ClientSaysComponent;
