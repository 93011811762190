import React from "react";
import Navbar from "../../components/user/Navbar";
import Footer from "../../components/user/Footer";
import img1 from "../../images/500_F_167160283_lDy9lOmEhSXSvPePH3jXddYgteTACYCn-1.jpg"

const  SingleTestimonialRajiv = () => {
  return (
    <div>
      <Navbar/>
      
      <div className="single-testimonial">
        <div>

              <div className="single-testimonial-img">
                <img src={img1} alt="" />
              </div>
              <div className="single-testimonial-name">Rajiv Bhaveja</div>
              <div className="single-testimonial-text">I recently book GarageOnCall for my Bike servicing and the service was very smooth and fast. It is very convenient and trustable. I Would highly recommend them and will use their service in future as well.</div>
              
          
       
        </div>
      </div>

<Footer/>
      
    </div>
  );
};

export default  SingleTestimonialRajiv;
