import React, { useEffect, useState } from "react";
import AdminNav from './AdminNav'
import LoadingComponent from "../user/LoadingComponent";

const AdminInquiryComponent = () => {

         
const [loading, setLoading] = useState(false)
const [popup, setPopup] = useState(false)
const [save, setSave] = useState("")

const [condition, setCondition] = useState("all")

const [showFilter, setShowFilter] = useState(true)

    
// ---------------------- fetch all -------------------------
  
const [booking, setBooking] = useState([]);

const loadData = async () => {
  let res = await fetch("https://api.shop.garageoncall.com/contact", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
    },
  });
  res = await res.json();
  console.log("res", res.data);
  setBooking(res.data.reverse());
};

useEffect(() => {
  loadData();
}, []);



  // -------------------------- update ---------------------------
  
  
  const [update, setUpdate] = useState({
    user: "",
    status: "",
  });
  
  const handleChange = (e) => {
    const value = e.target.value;
    setUpdate({
      ...update,
      [e.target.name]: value,
    });
  };
  
  const handleSubmit = async (e) => {
    setLoading(true);
  
    e.preventDefault();
    try {
      const response = await fetch(`https://api.shop.garageoncall.com/contact/${save._id}`, {
        method: "PUT",
        headers: { 
          "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
    },
        body: JSON.stringify(update),
      });
  
      if (response.ok) {
        alert("UPDATE successfully");
        window.location.reload();
        // onClose()
      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }
  
      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);
  };
  
    
    
  return (
    <div>
      {
        loading?
<LoadingComponent/>
:""
      }

<div>
<AdminNav/>


{
  popup===false?("")
  :(
      <div>
        <div className="update-form-main">
      <div className="update-form-content">
        <div className="update-form-close"><span onClick={()=>{setPopup(false)}}>X</span></div>
    <div 
    className={
      save.user === false
        ? "admin-data-list-status-red update-form-head"
        : save.status === false
        ? "admin-data-list-status-green update-form-head"
        : "admin-data-list-status-yellow update-form-head"
    }
    >{new Date(save.date).toDateString()} &nbsp; &nbsp; - &nbsp; &nbsp; {new Date(save.date).toLocaleTimeString()}</div>

        <div className="update-form-data">

          <div className="update-form-data-list">
            <div className="update-form-data-heading">Number</div>
            <div className="update-form-data-text">{save.phone}</div>
          </div>

          <div className="update-form-data-list">
            <div className="update-form-data-heading">Form</div>
            <div className="update-form-data-text">{save.path}</div>
          </div>

{/*     
          <div className="update-form-data-list">
            <div className="update-form-data-heading">Date</div>
            <div className="update-form-data-text">{new Date(save.date).toDateString()}</div>
          </div>

          <div className="update-form-data-list">
            <div className="update-form-data-heading">Time</div>
            <div className="update-form-data-text">{new Date(save.date).toLocaleTimeString()}</div>
          </div> */}

        <form onSubmit={handleSubmit}>
          <div className="update-form-list">
            <div className="update-form-lable">User</div>
              <select name="user" required id="" onChange={handleChange}>
                <option selected disabled value="">--- select option --</option>
                <option value="true">Genuine user</option>
                <option value="false">Fake user</option>
              </select>
          </div>

          <div className="update-form-list">
            <div className="update-form-lable">Inquiry</div>
            <div className="update-form-input">
              <div><input type="radio" required name="status" value="true"  onChange={handleChange}/><span>booked</span></div>
              <div><input type="radio" required name="status" value="false" onChange={handleChange}/><span>Complete</span></div>
              
            </div>
           
          </div>
          <button
          className={
            save.user === false
              ? "admin-data-list-status-red update-form-button"
              : save.status === false
              ? "admin-data-list-status-green update-form-button"
              : "admin-data-list-status-yellow update-form-button"
          }
          ><span>Confirm</span> </button>

          
        </form>
      </div>
      </div>
    </div>
      </div>
  )
}


{/* --------------------------------------------------------------------------- */}




<div className="admin-data">

  <div className="admin-data-header">
    <div className="admin-data-heading">{condition} Inquiry</div>

    <div  onClick={e=>{setShowFilter(!showFilter)}} className="admin-data-filter">
      <div><i className="fa-solid fa-bars-staggered"></i> &nbsp; <span>Filter</span></div>

      {
        showFilter === true ?""
        :
      <div  className="admin-data-filter-col" >
        <div className="admin-data-filter-option" onClick={e=>{setCondition("all"); setShowFilter(true)}}>- All Inquiry </div>
        <div className="admin-data-filter-option" onClick={e=>{setCondition("pending"); setShowFilter(true)}}>- Pending Inquiry</div>
        <div className="admin-data-filter-option" onClick={e=>{setCondition("complete"); setShowFilter(true)}}>- Complete Inquiry</div>
        <div className="admin-data-filter-option" onClick={e=>{setCondition("blocked"); setShowFilter(true)}}>- Blocked Inquiry</div>
      </div>
}
      
    </div>
    
  </div>

    
  {/* ------------------------------------------------------ */}

  
  {/* ------------------------------------------------------ */}





  <div className="admin-data-list">
  {/* fetchLocation && fetchLocation.filter((e)=>(e.location === parameter)).map(e=>{ */}

  {
  //  booking.filter((e)=>(e.user == false) ).map(e=>{
   booking.filter((e)=>(condition === "all"? {}: condition === "pending"? ((e.user) && (e.status)): condition === "complete"? ((e.user) && (!e.status)): condition === "blocked"? (!e.user): "") ).map(e=>{
    
  //  booking.map(e=>{
          return(

    <div className="admin-data-list-col">           
      
      <div
      className={
        e.user === false
          ? "admin-data-list-status-red admin-data-list-col-header"
          : e.status === false
          ? "admin-data-list-status-green admin-data-list-col-header"
          : "admin-data-list-status-yellow admin-data-list-col-header"
      }
      >
        {new Date(e.date).toDateString()}  &nbsp;  &nbsp; -  &nbsp;  &nbsp; {new Date(e.date).toLocaleTimeString()}</div>



      <div className="admin-data-list-col-data">
        <div className="admin-data-list-col-data-heading">Number</div>
        <div className="admin-data-list-col-text">{e.phone}</div>
      </div>



      {/* <div className="admin-data-list-col-data">
        <div className="admin-data-list-col-data-heading">Detected City</div>
        <div className="admin-data-list-col-text">{e.detectedCity}</div>
      </div> */}


      {/* <div className="admin-data-list-col-data">
        <div className="admin-data-list-col-data-heading">Selected City</div>
        <div className="admin-data-list-col-text">{e.selectedCity}</div>
      </div> */}


      <div className="admin-data-list-col-data">
        <div className="admin-data-list-col-data-heading">Form</div>
        <div className="admin-data-list-col-text">{e.path}</div>
      </div>



      <div className="admin-data-list-col-data">
        <div className="admin-data-list-col-data-heading">User</div>
        <div className="admin-data-list-col-text">{e.user === true ? "Genuine User" : "Blocked User"}</div>
      </div>


      <div className="admin-data-list-col-data">
        <div className="admin-data-list-col-data-heading">Inquiry</div>
        <div className="admin-data-list-col-text">{e.status === true ? "Booked" : "Completed"}</div>
      </div>

      <div className={
        e.user === false
          ? "admin-data-list-status-red admin-data-list-col-button"
          : e.status === false
          ? "admin-data-list-status-green admin-data-list-col-button"
          : "admin-data-list-status-yellow admin-data-list-col-button"
      }
      onClick={()=>{setPopup(true); setSave(e)}}
      >edit</div>

    </div>


          )
        })
      }

  </div>
</div>
</div>
        
    </div>
  )
}

export default AdminInquiryComponent