import React from 'react'
// import img0 from '../images/download.png'
// import img0 from '../images/Untitled-4.png'
// import img0 from '../../images/bike png.png'
import img1 from '../../images/bike-service-at home-noida.png'


const OurService = () => {
    return (
        <div>

            {/* <div>
                <div className='our-service'>

                    <div className='our-service-head'>
                        <div className='our-service-c-heding-1'>Our Service</div>
                        <div className='our-service-c-heding-2'>Doorstep Bike Service and Repair</div>

                    </div>

                    <div className='our-service-content'>

                        <div className='our-s-col-1'>
                            <div className='our-s-c-heading'>We Work</div>
                            <div className='our-s-c-points'>
                                <div>Book your service on our website</div>
                                <div>Evaluation and price estimate</div>
                                <div>Our engineer reaches at your doorstep</div>
                                <div>You can watch bike serviced live</div>
                                <div>Invoicing and payment </div>
                            </div>

                        </div>

                        <div className='ourService-img'>
                            <img src={img0} />
                        </div>

                        

                        <div className='our-s-col-2'>
                            <div className='our-s-c-heading'>Benefits</div>
                            <div className='our-s-l-points'>
                                <div>Expert Technicians</div>
                                <div>Convenience at Your Doorstep</div>
                                <div>Customer Satisfaction Guaranteed</div>
                                <div>Prompt and Professional Service</div>
                                <div>Genuine Parts and Lubricants</div>
                                <div>Transparent Pricing</div>
                                
                            </div>
                        </div>

                    </div>


                </div>
            </div> */}

<div className="our-serv">
        <div className="our-serv-header">
          <div className="our-serv-header-heading">Our <span>Service</span></div>
          <div className="our-serv-header-text">
            Doorstep Bike Service and Repair
          </div>
        </div>
        <div className="our-serv-content">
          <div className="our-serv-content-col-1">
            <div className="our-serv-content-col-heading">We Work</div>
            <div className="our-serv-content-col-list">
              <div className="our-serv-content-col-list-point">Book your service on our website</div>
              <div className="our-serv-content-col-list-point">Our engineer reaches at your doorstep</div>
              <div className="our-serv-content-col-list-point">You can watch bike serviced live</div>
              <div className="our-serv-content-col-list-point">Evaluation and price estimate</div>
              <div className="our-serv-content-col-list-point">Invoicing and payment</div>

            </div>
          </div>
          <div className="our-serv-content-img">
            <img src={img1} title='Doorstep bike service' alt="bike-service-at home-noida" />
          </div>

          <div className="our-serv-content-col-2">
            <div className="our-serv-content-col-heading">Benefits</div>
            <div className="our-serv-content-col-list">
              <div className="our-serv-content-col-list-point">Expert Technicians</div>
              <div className="our-serv-content-col-list-point">Convenience at Your Doorstep</div>
              <div className="our-serv-content-col-list-point">Customer Satisfaction Guaranteed</div>
              <div className="our-serv-content-col-list-point">Prompt and Professional Service</div>
              <div className="our-serv-content-col-list-point">Genuine Parts and Lubricants</div>
              <div className="our-serv-content-col-list-point">Transparent Pricing</div>

            </div>
          </div>
        </div>
      </div>

        </div>
    )
}

export default OurService