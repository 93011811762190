import React from 'react'
import FilterBlogPageComponent from '../../components/blogs/FilterBlogPageComponent'
import BlogNavbar from '../../components/blogs/BlogNavbar'
import Footer from '../../components/user/Footer'

const FilterBlogPage = ({type}) => {
  return (
    <div>
        <BlogNavbar/>
         <FilterBlogPageComponent type={type}/>
         <Footer/>
    </div>
  )
}

export default FilterBlogPage