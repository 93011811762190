import React from "react";
import Navbar from "../../components/user/Navbar";
import Footer from "../../components/user/Footer";

const RegisterAsAPartner = () => {
  return (
    <div>
      <Navbar />

      <div className="register-partner">
        <div className="register-partner-header">Register as a partner</div>
        <div className="register-partner-content">
          <form>
            <div>
              <label>Full Name*</label>
              <input required type="text" placeholder="Full Name" />
            </div>

            <div>
              <label>Email*</label>
              <input required type="email" placeholder="Your Email" />
            </div>

            <div>
              <label>Number*</label>
              <input required type="number" placeholder="Mobile Number" />
            </div>

            <div>
              <label>City*</label>
              <input required type="text" placeholder="Your City" />

              {/* <select name="" id="">
                <option disabled selected value="">
                  Plese Select
                </option>
                <option value="">Delhi</option>
                <option value="">Noida</option>
                <option value="">Greater Noida</option>
                <option value=""></option>
              </select> */}
              
            </div>

            {/* <div>
              <label>Your Photo* </label>
              <input type="file" />
              <br />
              <span>(accepted format png, jpg, jpeg etc.)</span>
            </div>

            <div>
              <label>Aadhaar* </label>
              <input type="file" />
              <br />
              <span>(accepted format png, jpg, jpeg etc.)</span>
            </div> */}

            <button> Register </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RegisterAsAPartner;
