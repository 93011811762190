import React, { useState } from 'react'

import warrantyIcon from "../../images/icon/warranty.svg";
import expertsIcon from "../../images/icon/experts.svg";
import bestcustomerIcon from "../../images/icon/bestcustomer.svg";
import LoadingComponent from '../user/LoadingComponent';
import { useNavigate } from 'react-router-dom';




const NumberForm = ({heading, text, heading2}) => {

  const [loading, setLoading] = useState(false);


  // post ----------------------------------------------------------

  const navigate = useNavigate()


  const [user, setUser] = useState({
    phone: "",
    path: heading,
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.shop.garageoncall.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        alert("Service booked successfully");
        navigate("/thanks");

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }

    } catch (error) {
      alert("server error");
    }
    setLoading(false);

  };




  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      

        <div id="location-form" className="area-location-header-col-2">
              <div className="area-location-header-col-2-content">
                <div className="area-location-header-col-2-headings">
                  <div className="area-location-header-col-2-heading-1">
                    {/* <div className="text-capitalize">&nbsp;{bike}&nbsp;</div> */}
                    <div className="text-capitalize">{heading?heading:"Bike Service at Home"}</div>
                  </div>
                  <div className="area-location-header-col-2-heading-1-text"><b>{text?text:""}</b></div>
                  <div className="area-location-header-col-2-heading-2">
                     {heading2?heading2:"Now Get Your Bike Service at Home and Save Your Time and Money!"}

                  </div>
                </div>

                <div className="area-location-header-col-2-form">


                  <form  onSubmit={handleSubmit}>
                    <div className="sb-c3-1-col"></div>

<h3>Enter your mobile number</h3>

                    <input
                      type="number"
                      placeholder="phone number*"
                      required
                      name="phone"
                      onChange={handleChange}
                    />

                    <button>Book Now</button>
                  </form>

                  <div className="area-location-header-col-2-end">
                    <div className="area-location-header-col-2-end-col">
                      <div className="area-location-header-col-2-end-icon">
                        <img src={warrantyIcon} alt="icon" title="icon" />
                      </div>
                      <div className="area-location-header-col-2-end-text">
                        10 Days Service Warranty
                      </div>
                    </div>

                    <div className="area-location-header-col-2-end-col">
                      <div className="area-location-header-col-2-end-icon">
                        <img src={expertsIcon} alt="icon" title="icon" />
                      </div>
                      <div className="area-location-header-col-2-end-text">
                        Expert <br />
                        Mechanics
                      </div>
                    </div>

                    <div className="area-location-header-col-2-end-col">
                      <div className="area-location-header-col-2-end-icon">
                        <img src={bestcustomerIcon} alt="icon" title="icon" />
                      </div>
                      <div className="area-location-header-col-2-end-text">
                        Best Customer
                        <br /> Support{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default NumberForm