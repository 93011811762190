import React from 'react'
import img from '../../../images/blogs/scooty-repair-at-home.jpg'
import Footer from '../../user/Footer'
import TopBlog from '../TopBlog'
import BlogSeo from './BlogSeo'
import BlogNavbar from '../BlogNavbar'

const BlogGarageOnCall = () => {
  return (
    <div>

<BlogSeo/>

        
          <div>
                <BlogNavbar />

                <div className='single-blog-main'>

                    <div className='single-blog-cols'>

                        <div className='s-b-col'>

                            <div className='s-b-col-1'>
                                <div className='s-b-c-head'>
                                    <h1 className='s-b-c-h-h-1'>Say No to Local unprofessional Garage</h1>
                                    <div className='s-b-c-h-h-2'>
                                        <div><i class="fa-solid fa-user"></i> ADMIN</div>
                                        <div><i class="fa-solid fa-calendar-days"></i> March 13, 2019</div>
                                    </div>

                                </div>
                                <div className='s-b-c-img'><img title="blog-img"  src={img} alt='scooty-repair-at-home' /></div>
                                <div className='s-b-c-para'>
                                    <h2 className='s-b-c-p-h'>
                                    Garage on Call
                                    </h2>

                                    <div className='s-b-c-p-t'>
                                    Get your Bike serviced from the comfort of your home or office by

Garage on Call
there is no need to go to unprofessional mechanic.
                                           </div>
                                    



                                </div>

                            </div>


                        </div>


                        
                        
       <div><TopBlog /></div>





                    </div>


                </div>


                <Footer />

            </div>
    </div>
  )
}

export default BlogGarageOnCall