import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import BookingComponent from '../../components/user/BookingComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';


const BookingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Book Online Bike Service and Repairing</title>
        <meta name="description" content="Experience hassle-free bike maintenance and repairs right at your doorstep! Book your service today for ultimate convenience and peace of mind." />
      </Helmet>
        
        <Navbar />

        <BookingComponent tag="h1" heading="Booking Page Booking Form"/>
        

        <Footer />
        <ButtonComponent />

    </div>
  )
}

export default BookingPage