import React from 'react'
import { Link } from 'react-router-dom'

const BikeBrandLocationComponent = () => {
  return (
    <div>
        
      <div className="bike-brand-location">
        <div className="landing-page-location-heading">
          Bike <span>Brands </span>We Service at <span>Home</span> by{" "}
          <span>Experts</span>
        </div>

        <div>
          <div className="bike-brand-location-content">
            <Link title="TVS doorstep bike service at home"  to="/bike/tvs">TVS</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Bajaj doorstep bike service at home"  to="/bike/bajaj">Bajaj</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Royal Enfield doorstep bike service at home"  to="/bike/royal-enfield">Royal Enfield</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Yamaha doorstep bike service at home"  to="/bike/yamaha">Yamaha</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Honda doorstep bike service at home"  to="/bike/honda">Honda</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Hero doorstep bike service at home"  to="/bike/hero">Hero</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Suzuki doorstep bike service at home"  to="/bike/suzuki">Suzuki</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="KTM doorstep bike service at home"  to="/bike/ktm">KTM</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Jawa doorstep bike service at home"  to="/bike/jawa">Jawa</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Harley Davidson doorstep bike service at home"  to="/bike/harley-davidson">Harley Davidson</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Ducati doorstep bike service at home"  to="/bike/ducati">Ducati</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Kawasaki doorstep bike service at home"  to="/bike/kawasaki">Kawasaki</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Benelli doorstep bike service at home"  to="/bike/benelli">Benelli</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Triumph doorstep bike service at home"  to="/bike/triumph">Triumph</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Indian doorstep bike service at home"  to="/bike/indian">Indian</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="BMW doorstep bike service at home"  to="/bike/bmw">BMW</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Aprilia doorstep bike service at home"  to="/bike/aprilia">Aprilia</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Yezdi doorstep bike service at home"  to="/bike/yezdi">Yezdi</Link> &nbsp;&nbsp; / &nbsp;&nbsp;
            <Link title="Husqvarna doorstep bike service at home"  to="/bike/husqvarna">Husqvarna</Link>
          </div>
        
        
        
      </div>

      {/* ----------------------------- bike brands end -------------------------- */}

    </div>
      
    </div>
  )
}

export default BikeBrandLocationComponent