import React from 'react'
import img from '../../../images/blogs/bike-repair-on-call.jpg'
import Footer from '../../user/Footer'
import TopBlog from '../TopBlog'
import BlogSeo from './BlogSeo'
import BlogNavbar from '../BlogNavbar'


const BlogBenefits = () => {
  return (


    <div>

<BlogSeo/>

      <BlogNavbar />

      <div className='single-blog-main'>

        <div className='single-blog-cols'>

          <div className='s-b-col'>

            <div className='s-b-col-1'>
              <div className='s-b-c-head'>
                <h1 className='s-b-c-h-h-1'>Benefits of availing doorstep bike repairing and servicing.</h1>
                <div className='s-b-c-h-h-2'>
                  <div><i class="fa-solid fa-user"></i> ADMIN</div>
                  <div><i class="fa-solid fa-calendar-days"></i> July 13, 2019</div>
                </div>

              </div>
              <div className='s-b-c-img'><img title="blog-img" src={img} alt='bike-repair-on-call'/></div>
              <div className='s-b-c-para'>

                <h2 className='s-b-c-p-h'>
                  Experts know how to do it the best way
                </h2>
                <div className='s-b-c-p-h'>
                  Your vehicle needs constant care
                </div>
                <div className='s-b-c-p-h'>
                  Customer friendly service and variable packages
                </div>

                <div className='s-b-c-p-t'>
                  Getting your bike to a professional garage or service provider has multiple advantages that you would surely like to explore.A well maintained and a thorough vehicle makes you save up on your time, and increase the resale value of the same. Not just this, a well maintained vehicle will save a lot of your money.
                  To establish a better perspective for you, here are the top reasons why you need to avail doorstep bike servicing
                </div>

              </div>

            </div>


          </div>


          <div><TopBlog /></div>





        </div>


      </div>


      <Footer />

    </div>



  )
}

export default BlogBenefits