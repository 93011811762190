import React from "react";
import Navbar from "../../components/user/Navbar";
import Footer from "../../components/user/Footer";
import img1 from "../../images/240_F_314597606_hbliBt0jRNNbLlVkAoAGtmcxgtkvBnZj.jpg"

const SingleTestimonialAnkit = () => {
  return (
    <div>
      <Navbar/>
      
      <div className="single-testimonial">
        <div>

              <div className="single-testimonial-img">
                <img src={img1} alt="" />
              </div>
              <div className="single-testimonial-name">Ankit Sharma</div>
              <div className="single-testimonial-text">Their doorstep Bike service is not only efficient it's also very affordable and safe for working girls like me. standing at local garage is horrible for bike servicing i recommend GarageOnCall.com and give 10/10.</div>
              
          
       
        </div>
      </div>

<Footer/>
      
    </div>
  );
};

export default SingleTestimonialAnkit;
