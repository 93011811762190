import React from 'react'
import BlogNavbar from '../../components/blogs/BlogNavbar'
import { Link } from 'react-router-dom'
import Footer from '../../components/user/Footer'

const BlogErrorPage = () => {
  return (
    <div>


        <BlogNavbar/>
        
             
<div className="error-page">
  <div className='error-page-heading-1'>404 Not Found</div>
  <div className='error-page-heading-2'>Your visited page not found. You may go home / blog page.</div>

  <div className='error-page-links'>
  <Link to="/blog" className='error-page-heading-3'>Back to Blog Page</Link>
  <Link to="/" className='error-page-heading-3'>Back to Home Page</Link>

  </div>
  
</div>


<Footer/>
        
        
    </div>
  )
}

export default BlogErrorPage