import React from 'react'
// import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
// import BlogComponent from '../../components/user/BlogComponent'
import { Helmet } from 'react-helmet';
import BlogNavbar from '../../components/blogs/BlogNavbar';
import BlogPageComponent from '../../components/blogs/BlogPageComponent';
// import BlogComponent from '../../components/user/BlogComponent';

const BlogPage = () => {
  return (
    <div>
      
      
<Helmet>
        <title>Book Bike Mechanic Online to service your bike at home</title>
        <meta name="description" content="
        We provide online platform to book a bike mechanic for any kind of bike related problems Bike mechanic reach at your home to repair and service your bike.
        " />
      </Helmet>

      
        {/* <Navbar /> */}
        {/* <BlogComponent /> */}
        {/* <Footer /> */}


<BlogNavbar/>
      <BlogPageComponent/>
      <Footer/>
        
        
      
    </div>
  )
}

export default BlogPage