import React from 'react'
import img from '../../../images/blogs/bike-service-at-home-delhi.jpg'
import Footer from '../../user/Footer'
import TopBlog from '../TopBlog'
import BlogSeo from './BlogSeo'
import BlogNavbar from '../BlogNavbar'

const BlogDoorstepBikeServicing = () => {
  return (
    <div>
          <div>

          <BlogSeo/>
      
<div>
    <BlogNavbar />

    <div className='single-blog-main'>

      <div className='single-blog-cols'>

        <div className='s-b-col'> 

          <div className='s-b-col-1'>
            <div className='s-b-c-head'>
              <h1 className='s-b-c-h-h-1'>Doorstep Bike Servicing and Repairing</h1>
              <div className='s-b-c-h-h-2'>
                <div><i class="fa-solid fa-user"></i> ADMIN</div>
                <div><i class="fa-solid fa-calendar-days"></i>  April 13, 2019</div>
              </div>

            </div>
            <div className='s-b-c-img'><img  title="blog-img" src={img} alt='bike-service-at-home-delhi' /></div>
            <div className='s-b-c-para'>
              <h2 className='s-b-c-p-h'>
              Doorstep Bike servicing
                 </h2>

                 <div className='s-b-c-p-t'>
                 doorstep Bike servicing
, repairing and breakdown services our mechanic reach at your doorstep to repair any kind of bike related problem or regular bike servicing at home.

Garage on Call offer online booking services for Bike servicing and repairing at customer preferred location whether it’s home or office.
                </div>
             
              
              

            </div>

          </div>


        </div>


       
       
        <div><TopBlog /></div>





      </div>


    </div>


<Footer />
    
</div>
    
      
    </div>
    </div>
  )
}

export default BlogDoorstepBikeServicing
