import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";


const OfferPopup = ({ open, onClose }) => {


  const [loading, setLoading] = useState(false);


  // post ----------------------------------------------------------

  const navigate = useNavigate()

      
  const [user, setUser] = useState({
    phone: "",
    path: "Offer popup form",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({ 
      ...user,
      [e.target.name]: value, 
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.shop.garageoncall.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // setBookingForm(false);
        alert("Service booked successfully");
        navigate("/booking-confirm");

      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);

  };

  






  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------


  if (!open) return null;


  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className='offer-popup'>


        <div className='offer-section'>
          <button className='offer-closeBtn' onClick={onClose}> <i class="fa-regular fa-circle-xmark offer-icon"></i></button>
          <div className='offer-popup-main'>
            <div className='offer-content'>
              <div className='offer-text-1'>Get Upto</div>
              <div className='offer-text-2'>30% OFF</div>
              <div className='offer-text-3'>On Bike Service and parts replacement</div>
              <form  onSubmit={handleSubmit} >
                <input placeholder='enter your number'
                  required
                  type="number"
                  name="phone"
                  onChange={handleChange} 
                /><br />
                <button className='offer-claim' type="submit" ><div className="pop-outin">Book Service</div></button>

              </form>
            </div>
          </div>

        </div>


      </div>

    </div>
  )
}

export default OfferPopup