import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import OurTeamComponent from '../../components/user/OurTeamComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';

const OurTeamPage = () => {
  return (
    <div>
      <Helmet>
        <title>Professional Bike Service at Your Doorstep</title>
        <meta name="description" content="
        From routine maintenance to major repairs, trust us to keep your wheels spinning smoothly. Book bike service now. Contact - 9658153153.
        " />
      </Helmet>
      
        <Navbar />

<OurTeamComponent/>

        <Footer />
        <ButtonComponent />

    </div>
  )
}

export default OurTeamPage