import React, { useState } from "react";
import logo from "../../images/logo.png";
import { Link, useLocation } from "react-router-dom";

const BlogNavbar = () => {

  const location = useLocation();
  const path = location.pathname;
  
  
  
  const [menu, setMenu] = useState("")

  
  
  return (
    <div>
      
      
      <div className="blog-nav-main">
      <div className="blog-nav">
        <div>
      <Link title="Garage On Call - Blog" to="/blog" className="blog-nav-col-1"><img title="Garage On Call - Read Our Articles" src={logo} alt="Garage On Call - Logo" /></Link>

        </div>

      <div className={`blog-nav-col-2 ${menu}`}>
        {/* <Link title="blog-page" to="/">Home</Link> */}
        {/* <Link title="blog-page" to="/blog">Blog</Link> */}
        <Link onClick={e=>setMenu("")} className={path==="/blog"?"blog-nav-col-2-red":""} title="blog-page" to="/blog">Home</Link>
        <Link onClick={e=>setMenu("")} className={path==="/blog/category/bike"?"blog-nav-col-2-red":""} title="blog-page" to="/blog/category/bike">Bike</Link>
        <Link onClick={e=>setMenu("")} className={path==="/blog/category/scooty"?"blog-nav-col-2-red":""} title="blog-page" to="/blog/category/scooty">Scooty</Link>
        <Link onClick={e=>setMenu("")} className={path==="/blog/category/ev"?"blog-nav-col-2-red":""} title="blog-page" to="/blog/category/ev">Electric Vehicle</Link>
        <Link title="GOC" to="/">Garage On Call</Link>

<div className="blog-nav-button blog-nav-close-button"><i onClick={e=>setMenu("")}  className="fa-solid fa-circle-xmark"></i></div>
      </div>
<div className="blog-nav-col-3">
              <Link title='contact' target='_blank' to="https://www.facebook.com/garageoncallofficial/"  ><i class="fa-brands fa-facebook-f"></i></Link>
              <Link title='contact' target='_blank' to="https://x.com/i/flow/login?redirect_after_login=%2FGarageoncall"  ><i class="fa-brands fa-x-twitter"></i></Link>
              <Link title='contact' target='_blank' to="https://www.youtube.com/@GarageOnCall"  ><i class="fa-brands fa-youtube"></i></Link>
              <Link title='contact' target='_blank' to="https://www.linkedin.com/company/garage-on-call/"  ><i class="fa-brands fa-linkedin-in"></i></Link>         
              <Link title='contact' target='_blank' to="https://www.instagram.com/garageoncallofficial/"  ><i class="fa-brands fa-instagram"></i></Link>
            </div>

<div className="blog-nav-button"><i onClick={e=>setMenu("blog-nav-col-2-active")} className="fa-solid fa-bars"></i></div>

        
      </div>
      </div>
      

      
    </div>
  );
};

export default BlogNavbar;
