import React from 'react'

const FormTermsAndConditionComponent = ({price, open}) => {
  return (
    <div>
        <div className="package-price-tc">
          <div className="package-price-tc-content">
            <div className="package-price-tc-heading">Terms and condition</div>
            <div className="package-price-tc-points">
              <ul>
                <li>Additional charges apply for the replacement of bike parts.</li>
                <li>
                  Visiting charges ₹ {price?price:"299"} to be paid even if the services are
                  not availed.
                </li>
                <li>
                  Price of the Engine Oil may vary from location to location and
                  brands.
                </li>
                <li>Only for RSA - All checkpoints are not necessary if the bike starts properly.</li>
                {/* <li>Only for Road Side Assistance - All checkpoints are not necessary if the bike starts properly.</li> */}
                {/* <li>GST will be charged on the total bill.</li> */}
              </ul>
            </div>
            <div className="package-price-tc-button">
              <span onClick={(e) => open(false)}>OK</span>
            </div>



        



        
          </div>
        </div>

        
        
    </div>
  )
}

export default FormTermsAndConditionComponent