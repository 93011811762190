import React from 'react'
import img from '../../../images/blogs/two-wheeler-service-at-home.png'
import Footer from '../../user/Footer'
import TopBlog from '../TopBlog'
import BlogSeo from './BlogSeo'
import BlogNavbar from '../BlogNavbar'

const BlogServicing = () => {
  return (

<div>

<BlogSeo/>

  
    <BlogNavbar />

    <div className='single-blog-main'>

      <div className='single-blog-cols'>

        <div className='s-b-col'> 

          <div className='s-b-col-1'>
            <div className='s-b-c-head'>
              <h1 className='s-b-c-h-h-1'>Bike servicing – why is it necessary</h1>
              <div className='s-b-c-h-h-2'>
                <div><i class="fa-solid fa-user"></i> ADMIN</div>
                <div><i class="fa-solid fa-calendar-days"></i> Sept 13, 2019</div>
              </div>

            </div>
            <div className='s-b-c-img'><img title="blog-img" src={img} alt='two-wheeler-service-at-home' /></div>
            <div className='s-b-c-para'>
              <h2 className='s-b-c-p-h'>The Importance Of Bike Servicing</h2>
              <div className='s-b-c-p-t'>
                For the smooth running, longevity and safety of your bike, regular checks should be carried out to ensure it’s running correctly. The harsh conditions of the winter can be severely detrimental to a bikes performance and both the moving and static parts can be effected. In the road riding spectrum, the increased wet conditions of the winter combined with the constant gritting of the roads mean grime can be driven into bearings, chains and brake pads. For the devoted mountain bikers riding through the winter, the muddy, sludgy conditions that the trails can turn to make the servicing of your bike a high priority if you want it to continue riding like new. The most important reason to keep your bike serviced (whatever your discipline) is safety – if your bike is maintained and regularly serviced, the less likelihood of a mechanical issue and the safer your ride is.
              </div>
              <div className='s-b-c-p-h'>Maintain, Retain and Benefit</div>
              <div className='s-b-c-p-t'>
                If you want your bike to ride smoothly and allow you to focus 100% on your technique and the ride itself, then you will want to keep your bike tuned and serviced. For this, you’ll be rewarded, as a well looked after bike will increase your motivation, increase your performance, and enable you achieve your end goal faster. Your bike is an integral part of your cycling experience which needs as much attention as your nutrition or training plan.
              </div>

            </div>

          </div>


        </div>


       
        
       <div><TopBlog /></div>




      </div>


    </div>


<Footer />
    
</div>
    
  )
}

export default BlogServicing