import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'

const ErrorPage = () => {
  return (
    <div>
        {/* <div className="error-page">
            <br />
            <br />
            <h1>Error 404</h1>
            <br />
            <h2>page not found</h2>
            <br />
            <h2><Link to="/">go to home</Link></h2>
            <br />
        </div> */}

       
       <Navbar/>
       
<div className="error-page">
  <div className='error-page-heading-1'>404 Not Found</div>
  <div className='error-page-heading-2'>Your visited page not found. You may go home page.</div>
  <Link to="/" className='error-page-heading-3'>Back to home page</Link>
</div>


<Footer/>

        
    </div>
  )
}

export default ErrorPage