// import React from "react";
// import { Link } from "react-router-dom";

// const TopBlog = () => {
//   return (
//     <div>
//         <div className="s-b-col-2">
//         <div className="s-b-c2-h-1">Connect & Follow</div>

//         <div className="s-b-c2-icons">
//         <Link title='contact' target='_blank' to="https://www.facebook.com/garageoncallofficial/" ><i class="fa-brands fa-facebook-f"></i></Link>
//               <Link title='contact' target='_blank' to="https://x.com/i/flow/login?redirect_after_login=%2FGarageoncall" ><i class="fa-brands fa-x-twitter"></i></Link>
//               <Link title='contact' target='_blank' to="https://www.youtube.com/@GarageOnCall" ><i class="fa-brands fa-youtube"></i></Link>
//               <Link title='contact' target='_blank' to="https://www.linkedin.com/company/garage-on-call/" ><i class="fa-brands fa-linkedin-in"></i></Link>         
//               <Link title='contact' target='_blank' to="https://www.instagram.com/garageoncallofficial/" ><i class="fa-brands fa-instagram"></i></Link>
//             </div>
//         </div>

//         {/* ----------------------------------------------------------------------------- */}
        
//       <div className="s-b-col-2">

//         <div className="s-b-c2-h-1">Garage on Call – Top Blog</div>

//         <div className="s-b-c2-b">
//           <div className="s-b-c2-h-2">
//             Top 10 Best Beginner Motorcycles for New Riders?
//           </div>
//           <div className="s-b-c2-p">
//           Are you ready to embark on an exhilarating journey into the world of motorcycle riding? Whether you're drawn to the freedom of the open road or the thrill of mastering a new skill, 
//             <Link title="top blog"  target="blank" to="/top-10-best-beginner-motorcycles-for-new-riders"> read_more...</Link>
//           </div>
//         </div>



//         <div className="s-b-c2-b">
//           <div className="s-b-c2-h-2">
//           How to Perform Basic Motorcycle Maintenance at Home?
//           </div>
//           <div className="s-b-c2-p">
//           Are you tired of spending hefty amounts on basic motorcycle maintenance? Ready to take control and become the master of your own bike's upkeep? Look no further!{" "}
//             <Link title="top blog" 
//               target="blank"
//               to="/basic-motorcycle-maintenance-at-home"
//             >
//               read_more...
//             </Link>
//           </div>
//         </div>

//         <div className="s-b-c2-b">
//           <div className="s-b-c2-h-2">The Importance of Regular Motorcycle Servicing?
// </div>
//           <div className="s-b-c2-p">
//           Picture yourself cruising down an open road, wind in your hair, and the purr of your motorcycle's engine beneath you. It's a liberating feeling, isn't it? But what if I told you that this exhilarating experience could be cut short by a simple neglect of regular motorcycle servicing?{" "}
//             <Link title="top blog"  target="blank" to="/regular-motorcycle-servicing">
//               read_more...
//             </Link>
//           </div>
//         </div>

//         <div className="s-b-c2-b">
//           <div className="s-b-c2-h-2">Essential Safety Gear for Motorcyclists?</div>
//           <div className="s-b-c2-p">
//           When it comes to riding a motorcycle, there's exhilaration in every twist of the throttle and every bend of the road. But amidst the rush of wind and freedom, one truth remains unyielding: safety is paramount. The difference between a thrilling{" "}
//             <Link title="top blog" 
//               target="blank"
//               to="/essential-safety-gear-for-motorcyclists"
//             >
//               read_more...
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TopBlog;



import React from 'react'
import { Link } from 'react-router-dom'
import blogData from "../blogs/DataBlog.json"


const TopBlog = () => {
  
  return (
    <div className="our-blog-page-col-2-fix">
    <div className="our-blog-page-col-2">

    <div className="our-blog-page-col-2-col">
    <div className="our-blog-page-col-2-col-heading">Connect & Follow</div>
    <div className="our-blog-page-col-2-col-icon">
      <Link title='contact' target='_blank' to="https://www.facebook.com/garageoncallofficial/" ><i class="fa-brands fa-facebook-f"></i></Link>
        <Link title='contact' target='_blank' to="https://x.com/i/flow/login?redirect_after_login=%2FGarageoncall" ><i class="fa-brands fa-x-twitter"></i></Link>
        <Link title='contact' target='_blank' to="https://www.youtube.com/@GarageOnCall" ><i class="fa-brands fa-youtube"></i></Link>
        <Link title='contact' target='_blank' to="https://www.linkedin.com/company/garage-on-call/" ><i class="fa-brands fa-linkedin-in"></i></Link>         
        <Link title='contact' target='_blank' to="https://www.instagram.com/garageoncallofficial/" ><i class="fa-brands fa-instagram"></i></Link>
      </div>

    </div>
      

    <div className="our-blog-page-col-2-col">
    <div className="our-blog-page-col-2-col-heading">Latest Blog</div>
    {/* ------------------------------------------- */}


{
  blogData.slice(0, 4).map(e=>(

    <div className="our-blog-page-col-2-content">
      <Link to={e.link} title={e.title}>
    <div className="our-blog-page-col-2-content-heading">{e.title}</div>
      </Link>
    </div>
    
  ))
  }

    
    {/* ------------------------------------------- */}

    </div>
      
    </div>
    </div>
  )
}

export default TopBlog

