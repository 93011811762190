import React from 'react'
import NumberForm from './NumberForm'

const PopupNumberForm = ({open, heading, text, heading2}) => {
  return (
    <div>
                <div className="number-form">
        <div className="number-form-main">
        <div className="number-form-close"><i onClick={e=>open(false)} className="fa-solid fa-circle-xmark"></i></div>
            
<NumberForm heading={heading} text={text} heading2={heading2}/>

        </div>
            </div>
            
    </div>
  )
}

export default PopupNumberForm