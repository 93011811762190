import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import TestimonialComponent from '../../components/user/TestimonialComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';


const TestimonialPage = () => {
  return (
    <div>
      <Helmet>
        <title>Doorstep Bike Service Testimonial</title>
        <meta name="description" content="
        Read testimonials from satisfied customers who have experienced the ease and excellence of our professional maintenance team. Book Bike service now.
        " />
      </Helmet>
      
      <Navbar />

<TestimonialComponent tag="h1" />

<Footer />

<ButtonComponent/>
      
    </div>
  )
}

export default TestimonialPage