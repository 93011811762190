import React from 'react'
import AdminLoginComponent from '../../components/admin/AdminLoginComponent'

const AdminLoginPage = () => {
  return (
    <div>
        <AdminLoginComponent/>
    </div>
  )
}
 
export default AdminLoginPage