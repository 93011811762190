import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import LoadingComponent from '../user/LoadingComponent';

const AdminLoginComponent = () => {

  const [loading, setLoading] = useState(false);

    
    
    const navigate = useNavigate()


    
  const [user, setUser] = useState({
    numberOrEmail: "",
    password: "",
  });
  
  
  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value
    });
  };


  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    try {
      const response = await fetch("https://api.shop.garageoncall.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
      "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
    },
        body: JSON.stringify(user),
      });
 
      if (response.ok) {
        
        const res_data = await response.json();
        localStorage.setItem("userAuthToken", res_data.token)
        navigate("/admin/home");
        alert("login sucessful");
      } else {
        // console.log("invalid credential...");
        const res_data = await response.json();
        // console.log("err res", response)
        // console.log("err res data", res_data)
        alert(res_data.message)
        console.log("Login Error : ", res_data.message)
      }

      // console.log("try res ", response);
    } catch (error) {
      console.log("catch err" , error);
      alert("server error")
    }
    setLoading(false);

  };
  

  if(localStorage.getItem("userAuthToken")){
    return <Navigate to="/admin/home"/>
  }
    
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
        
        
        
<div className='admin-page'>
            <div className='admin-page-main'>
                <div className='admin-page-content'>
                    <div className='admin-p-head'>
                        <div className='admin-p-heading-1'><span> <i class="fa-solid fa-screwdriver-wrench"></i> Garage </span> On Call</div>
                        <div className='admin-p-heading-2'>Admin LogIn Only!</div>


                        {/* <div className='about-us-heading-2'>Welcome to <span> <i class="fa-solid fa-screwdriver-wrench"></i> </span> Garage On Call</div> */}

                    </div>
                    <div className='admin-p-form'>
                        <form  onSubmit={handleSubmit}>
                            <label>Email</label>
                            <input required placeholder='enter your email or number' type='text' name="numberOrEmail" onChange={handleChange} />
                            <label>Password</label>
                            <input required placeholder='enter password' type='password'name="password" onChange={handleChange} />

                            <button>Log in</button>

                        </form>
                        <Link to='/' ><i class="fa-solid fa-share fa-rotate-180"></i> go to home</Link>
                    </div>
                    <div className='admin-p-foot'>
                        <Link to="/disclaimer">Term & Condition</Link>
                        <Link to="/privacy-policy-2">Privacy Policy</Link>
                    </div>

                </div>

            </div>

            {/* <div className='admin-p-fix'></div> */}


        </div>
    </div>
  )
}

export default AdminLoginComponent