import React from "react";
import Navbar from "../../components/user/Navbar";
import Footer from "../../components/user/Footer";
import img1 from "../../images/6MWH9Xi_.jpg"

const SingleTestimonialMohit = () => {
  return (
    <div>
      <Navbar/>
      
      <div className="single-testimonial">
        <div>

              <div className="single-testimonial-img">
                <img src={img1} alt="" />
              </div>
              <div className="single-testimonial-name">Mohit Suri</div>
              <div className="single-testimonial-text">My Bike have some problem in engine it sounds more as usual which was not discovered by my local mechanic, So I recently take services of GarageOnCall.com and got my Bike serviced there, and the problem is now solved.</div>
              
          
       
        </div>
      </div>

<Footer/>
      
    </div>
  );
};

export default SingleTestimonialMohit;
