import React from 'react'
import { Helmet } from 'react-helmet'

const BlogSeo = () => {
  return (
    <div>
             
<Helmet>
        <title>Book Bike Mechanic Online to service your bike at home</title>
        <meta name="description" content="
        We provide online platform to book a bike mechanic for any kind of bike related problems Bike mechanic reach at your home to repair and service your bike.
        " />
      </Helmet>
    </div>
  )
}

export default BlogSeo