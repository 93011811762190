import React from 'react'
import Navbar from '../../components/user/Navbar'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';


const ContactMessagePage = () => {
  return (
    <div>

<Helmet>
        <title>Garage on call - Thanks For Contacting</title>
        <meta name="description" content="
        Garage on call - We offers Bike servicing at home in Noida, Delhi, Gurgaon and Ghaziabad. Call us for doorstep bike servicing and repairing +91 9658124124
        " />
      </Helmet>
      
         <Navbar/>


         <div className='booked-page'>
            <div className='booked-page-content'>
                {/* <div className='booked-page-headding-1'><i class="fa-regular fa-face-smile"></i> Thanks For Booking <i class="fa-regular fa-face-smile-beam"></i></div> */}
                <h1 className='booked-page-headding-2'>Thanks For Contacting</h1>
                <h2 className='booked-page-headding-3'>Our team will contact you shortly</h2>
                <div className='booked-page-headding-4'>Contact : <Link title='bike service at home' to="tel:9658124124"> +91-9658 124 124</Link></div>
                <div className='booked-page-headding-4'><Link title='bike service near me' to="/">go to home</Link></div>

                </div>

            </div>

         
            {/* <Footer /> */}
    </div>
  )
}

export default ContactMessagePage