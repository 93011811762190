import React from 'react'
import AdminNav from './AdminNav'
import { Link } from 'react-router-dom'

const AdminHomeComponent = () => {
  return (
    <div>
      <AdminNav/>
      <div className='team-h-heading-3'>Welcome to <span><i className="fa-solid fa-screwdriver-wrench"></i> Garage </span> on Call <span></span></div>

<div className="admin-home-list">
  <div>
<Link to="/admin/bookings"><i class="fa-solid fa-book-atlas"></i><div>Bookings</div></Link>
<Link to="/admin/inquiry"><i class="fa-solid fa-clipboard-question"></i><div>Inquiry</div></Link>
<Link to="/admin/contact-us"><i class="fa-solid fa-file-contract"></i><div>Contact Us Queries</div></Link>
{/* <Link to=""><i class="fa-solid fa-handshake"></i><div>Register as a partner</div></Link> */}
<Link><i class="fa-solid fa-motorcycle"></i><div>Bike Brand and Models</div></Link>
<Link><i class="fa-solid fa-location-dot"></i><div>Locations</div></Link>

  </div>

</div>



    </div>
  )
}

export default AdminHomeComponent