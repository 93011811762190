import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
// import PriceComponent from '../../components/user/PriceComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';
// import PackagePriceComponent from '../../components/user/PackagePriceComponent';
import PricePageComponent from '../../components/user/price/PricePageComponent';

const PricePage = () => {
  return (
    <div>
        
<Helmet>
        <title>Our Prices - Book online mechanic bike mechanic</title>
        <meta name="description" content="
       Say no to local garage Book a mechanic online for any bike related problems we will instantly reach at you doorstep for bike servicing and repairing
        " />
      </Helmet>

        <Navbar />

{/* <PriceComponent /> */}

{/* <PackagePriceComponent/> */}

<PricePageComponent/>


        <Footer />
        <ButtonComponent />

    </div>
  )
}

export default PricePage