import React from "react";
import { Link } from "react-router-dom";

const LatestPostComponent = () => {
  return (
    <div>
      <div className="latest-post">
        <div className="latest-post-heading">Latest <span>Post</span></div>
      {/* <div className="heading-underline"><hr /></div> */}

        
        <div className="latest-post-content">

          <div className="latest-post-col">
            <div className="latest-post-col-heading">How do I maintain the gearbox on my scooty?</div>
            <div className="latest-post-col-img">
              <img src="/static/media/gearbox-on-scooty.566ecc7207a3cc18199f.jpeg" alt="blog img" title="Best scooty service at home" />
            </div>
            <div className="latest-post-col-text">Maintaining the gearbox on your scooty is essential to ensure smooth operation, prolong the life of the transmission components, and enhance overall performance. Here’s a comprehensive guide on how to maintain the gearbox on your scooty:</div>
            <Link title="Doorstep scooty repair" target="_blank" to="/blog/how-do-maintain-gearbox-on-scooty" className="latest-post-col-link">read more...</Link>
          </div>
          

          <div className="latest-post-col">
            <div className="latest-post-col-heading">What are the advantages of a four-valve engine in bikes?</div>
            <div className="latest-post-col-img">
              <img src="/static/media/advantages-of-four.dc1cd511e5fe55bb9665.jpeg" alt="blog img" title="Doorstep best bike engine repare" />
            </div>
            <div className="latest-post-col-text">A four-valve engine, which features two intake valves and two exhaust valves per cylinder, offers numerous advantages over traditional two-valve engines in motorcycles. These benefits contribute to improved performance, efficiency, and overall</div>
            <Link title="super bike service at home" target="_blank" to="/blog/four-valve-engine-in-bikes" className="latest-post-col-link">read more...</Link>
          </div>
          

          <div className="latest-post-col">
            <div className="latest-post-col-heading">How do I maintain the drive belt on my scooty?</div>
            <div className="latest-post-col-img">
              <img src="/static/media/drive-belt.44e274a5deedcd794711.jpeg" alt="blog img" title="At home best scooty service and repair" />
            </div>
            <div className="latest-post-col-text">Maintaining the drive belt on your scooty is essential for ensuring smooth and reliable performance. The drive belt, also known as a V-belt or CVT (Continuously Variable Transmission) belt, is crucial for transferring power from the engine to the </div>
            <Link title="scooty repair at home" target="_blank" to="/blog/maintain-drive-belt-on-scooty" className="latest-post-col-link">read more...</Link>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default LatestPostComponent;
