import React from 'react'
import Navbar from '../../components/user/Navbar'
import Footer from '../../components/user/Footer'
import ContactComponent from '../../components/user/ContactComponent'
import ButtonComponent from '../../components/user/ButtonComponent'
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  return (
    <div>

            
<Helmet>
        <title>Call our bike mechanic for doorstep Bike servicing and repairing</title>
        <meta name="description" content="
        Garage on Call offers doorstep bike servicing and repairing in noida, greater noida and delhi now no need to go to local garage and unprofessional mechanic.
        " />
      </Helmet>
      
        
        <Navbar />

<ContactComponent/>

        <Footer />
        <ButtonComponent />

    </div>
  )
}

export default ContactPage